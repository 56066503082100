import { ref, computed } from "vue";

export enum DialogState {
  Closed,
  AskToOpen,
  Open,
}

export function useCreditNoteDialogService() {
  const showReturnStockDialog = ref<DialogState>(DialogState.Closed);

  const shouldShowReturnStockDialog = computed({
    get() {
      return showReturnStockDialog.value === DialogState.Open;
    },
    set() {
      showReturnStockDialog.value = DialogState.Closed;
    },
  });

  const shouldShowAskToOpenReturnStockDialog = computed({
    get() {
      return showReturnStockDialog.value === DialogState.AskToOpen;
    },
    set() {
      showReturnStockDialog.value = DialogState.Closed;
    },
  });

  const askToOpenReturnStockDialog = () => {
    showReturnStockDialog.value = DialogState.AskToOpen;
  };

  const openReturnStockDialog = () => {
    showReturnStockDialog.value = DialogState.Open;
  };

  return {
    showReturnStockDialog,
    shouldShowReturnStockDialog,
    shouldShowAskToOpenReturnStockDialog,
    askToOpenReturnStockDialog,
    openReturnStockDialog,
  };
}
