import { Client } from "../client/Client";
import { Address } from "./Address";

export class CreditNoteClient {
  clientId = "";
  name = "";
  businessNumber = "";
  phoneNumber = "";
  email = "";
  address = new Address();
  accountNumber = "";
  bankName = "";
  logoBase64 = "";

  static createFromClient(client: Client) {
    const creditNoteClient = new CreditNoteClient();
    creditNoteClient.clientId = client.id;
    creditNoteClient.name = client.name;
    creditNoteClient.businessNumber = client.businessNumber;
    creditNoteClient.phoneNumber = client.phoneNumber;
    creditNoteClient.email = client.email;
    creditNoteClient.address.addressLines = client.address.addressLines;
    creditNoteClient.address.city = client.address.city;
    creditNoteClient.address.postalCode = client.address.postalCode;
    creditNoteClient.address.countryIso = client.address.country;
    creditNoteClient.accountNumber = client.banking.accountNumber;
    creditNoteClient.bankName = client.banking.name;
    creditNoteClient.logoBase64 = client.logoBase64;

    return creditNoteClient;
  }
}
