import { useErrorHandler } from "../ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { Customer } from "@/models/customer/Customer";
import { customerApi } from "./CustomerApi";

export function useCustomerService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getCustomerById = async (customerId: string): Promise<Customer> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await customerApi.getById(authHeaders, customerId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { getCustomerById };
}
