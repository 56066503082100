<template>
  <label for="account-settings-economy-system">{{ t("account-settings.economy-system") }}</label>
  <InputText
    id="account-settings-economy-system"
    v-model="economySystem"
    :placeholder="t('placeholder.type', { property: t('account-settings.economy-system').toLowerCase() })"
    class="inputfield w-full"
    data-testid="c-account-settings-economy-system"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const economySystem = defineModel<string>("economySystem", {
  required: true,
});

const { t } = useI18n();
</script>
