<template>
  <FloatLabelInput
    id="credit-reference"
    :maxlength="8000"
    :label="t(`creditnote.reference`)"
    v-model:value="creditReference"
    :tabIndex="0"
    dataTestId="credit-refrence"
    :disabled="disabled"
    :focusInput="focusInput"
    :placeholder="t('placeholder.type', { property: t('creditnote.reference').toLowerCase() })"
  />
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const creditReference = defineModel("creditReference", {
  required: true,
});

defineProps<{
  disabled?: boolean;
  focusInput?: boolean;
}>();
</script>
