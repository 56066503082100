import { TaxType } from "./Taxtype";

export class Tax {
  id = "";
  companyId = "";
  clientId = "";
  taxName = "";
  rate = 0;
  taxType = TaxType.Outgoing;
  code = "";
  countryIso = "";
  description = "";
  isActive = false;
  isDefaultTaxForProduct = false;
  isDefaultTaxForFreight = false;
}
