export enum NumberSeriesType {
  None = "none",
  Invoice = "invoice",
  ZeroInvoice = "zero-invoice",
  CreditNote = "credit-note",
  Product = "product",
  Supplier = "supplier",
  Purchase = "purchase",
  Customer = "customer",
  Order = "order",
  PickingList = "picking-list",
}
