import { AccountSettingProduct } from "./AccountSettingProduct";
import { AccountType } from "./AccountType";
import { AttachmentType } from "./AttachmentType";
import { AccountSettingFreightMethod } from "./AccountSettingFreightMethod";
import { VatCode } from "./VatCode";
import { AccountSettingPaymentTerm } from "./AccountSettingPaymentTerm";

export class AccountSettings {
  id = "";
  clientIds: string[] = [];
  description = "";
  economySystem = "";
  products: AccountSettingProduct[] = [];
  attachmentTypes: AttachmentType[] = [];
  freightMethods: AccountSettingFreightMethod[] = [];
  vatCodes: VatCode[] = [];
  paymentTerms: AccountSettingPaymentTerm[] = [];
  accountTypes: AccountType[] = [];
}
