<template>
  <label for="account-plan-name">
    {{ t(`account-plan.dialog.name.label`) }}
  </label>
  <InputText
    id="account-plan-name"
    v-model="name"
    data-testid="account-plan-account-name"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.name.$error }"
    :placeholder="t(`account-plan.dialog.name.placeholder`)"
  />
  <small
    v-if="val.name.$error"
    id="account-plan-name-help"
    data-testid="account-plan-account-name-error"
    class="p-error"
    >{{ val.name.$errors[0].$message }}</small
  >
</template>

<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const name = defineModel<string>("name", {
  required: true,
});

const rules = {
  name: {
    required: helpers.withMessage(
      t("common.field-required", { fieldName: t("account-plan.dialog.name.label") }),
      required,
    ),
  },
};

const val = useVuelidate(rules, { name: name });
</script>
