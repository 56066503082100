import PrimeVue from "primevue/config";
import Aura from "@primeuix/themes/aura";
import { definePreset } from "@primeuix/themes";
import { i18n } from "./locales/i18n";
import { createPinia } from "pinia";
import { createApp } from "vue";

import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";

import Tooltip from "primevue/tooltip";
import vueDebounce from "vue-debounce";

import { FloatLabelInput, FloatLabelDropdownPanel, FloatLabelTextArea } from "@cumulus/components";

// Enable this line to debug directly from the Theme source code in CumulusComponents
//import { default as DefaultTheme } from "../../../SharedPackages/Npm/CumulusComponents/src/theme/default";
// And disable this line
import { DefaultTheme } from "@cumulus/components";
const MyPreset = definePreset(Aura, DefaultTheme);

export default function configureApp(app: ReturnType<typeof createApp>) {
  const pinia = createPinia();

  app.use(PrimeVue, {
    theme: {
      preset: MyPreset,
      inputVariant: "outlined", //"filled"
      options: {
        darkModeSelector: ".dark",
        cssLayer: {
          name: "primevue",
          order: "tailwind-base, primevue, tailwind-utilities",
        },
      },
    },
  });
  app.use(ConfirmationService);
  app.use(ToastService);
  app.use(i18n);
  app.use(pinia);

  app.component("FloatLabelInput", FloatLabelInput);
  app.component("FloatLabelDropdownPanel", FloatLabelDropdownPanel);
  app.component("FloatLabelTextArea", FloatLabelTextArea);

  app.directive("tooltip", Tooltip);
  app.directive("debounce", vueDebounce({ lock: true, listenTo: "input" }));
}
