<template>
  <DataTable
    :value="freightMethodSettings"
    class="c-datatable"
    dataKey="id"
    :autoLayout="true"
    responsiveLayout="scroll"
    stripedRows
    sortField="name"
    :sortOrder="1"
    data-testid="c-account-settings-freight-method-table"
  >
    <Column :header="t('account-settings.freight-method.freight-method')" class="max-w-40">
      <template #body="{ data, index }">
        <span v-if="data.isDefault" :data-testid="`c-account-settings-freight-method-${index}`">{{
          t("account-settings.standard")
        }}</span>
        <SelectFreightMethod
          v-else
          v-model:freightMethodId="data.id"
          :freightMethods="freightMethods"
          :freightMethodSettings="freightMethodSettings"
          :dataTestId="`c-account-settings-freight-method-${index}`"
          :isRequired="true"
        />
      </template>
    </Column>

    <Column :header="t('account-settings.freight-method.description')">
      <template #body="{ data, index }">
        <span :data-testid="`c-account-settings-freight-method-description-${index}`">{{
          data.isDefault
            ? t("account-settings.freight-method.default-description")
            : freightMethods.find((x) => x.id === data.id)?.description
        }}</span>
      </template>
    </Column>

    <Column :header="t('account-settings.freight-method.with-tax')" class="max-w-40">
      <template #body="{ data, index }">
        <SelectAccountPlan
          v-model:accountPlanId="data.freightAccountPlanId"
          :dataTestId="`c-account-settings-freight-account-plan-${index}`"
          :isDefault="data.isDefault"
        />
      </template>
    </Column>

    <Column :header="t('account-settings.freight-method.tax-free')" class="max-w-40">
      <template #body="{ data, index }">
        <SelectAccountPlan
          v-model:accountPlanId="data.freightTaxFreeAccountPlanId"
          :dataTestId="`c-account-settings-freight-tax-free-account-plan-${index}`"
          :isDefault="data.isDefault"
        />
      </template>
    </Column>

    <Column :header="t('account-settings.freight-method.incoming')" class="max-w-40">
      <template #body="{ data, index }">
        <SelectAccountPlan
          v-model:accountPlanId="data.freightIncomingAccountPlanId"
          :dataTestId="`c-account-settings-freight-incoming-account-plan-${index}`"
          :isDefault="data.isDefault"
        />
      </template>
    </Column>

    <Column>
      <template #header>
        <Button
          icon="pi pi-trash"
          class="p-button-rounded p-button-secondary p-button-text"
          :disabled="true"
          :aria-label="t('common.delete')"
        />
      </template>

      <template #body="slotProps">
        <Button
          v-if="!slotProps.data.isDefault"
          icon="pi pi-trash"
          class="p-button-rounded p-button-plain p-button-text"
          :data-testid="`purchase-order-line-delete-button-${slotProps.index}`"
          @click="deleteFreightMethodLine(slotProps.data)"
        />
      </template>
    </Column>

    <template #empty>
      <span>{{ t("account-settings.no-freight-settings") }}</span>
    </template>
  </DataTable>

  <Button
    class="p-button-text p-button-sm"
    data-testid="c-account-setting-add-freight-setting"
    @click="addNewFreightMethod"
  >
    <i class="c-plus"></i>
    <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
  </Button>

  <Popover ref="freightOverlay" appendTo="body" class="c-overlay-panel">
    <i class="pi pi-info-circle"></i>
    <span class="pl-2">{{ t("account-settings.no-freight-methods-available") }}</span>
  </Popover>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { onMounted, ref } from "vue";
import { FreightMethod } from "@/models/freight-method/FreightMethod";
import { AccountSettingFreightMethod } from "@/models/account-settings/AccountSettingFreightMethod";
import { useFreightMethodService } from "@/api/freight-method/FreightMethodService";
import SelectFreightMethod from "./SelectFreightMethod.vue";
import SelectAccountPlan from "./SelectAccountPlan.vue";

const freightMethodSettings = defineModel<AccountSettingFreightMethod[]>("freightMethodSettings", {
  required: true,
});

const { t } = useI18n();
const { getAllFreightMethods } = useFreightMethodService();

const freightMethods = ref<FreightMethod[]>([]);
const freightOverlay = ref();

const getFreightMethods = async () => {
  freightMethods.value = await getAllFreightMethods();
};

const addNewFreightMethod = (event: Event) => {
  const availableFreightMethods = freightMethods.value.filter(
    (x) => !freightMethodSettings.value.some((y) => y.id === x.id),
  );

  if (availableFreightMethods.length === 0) {
    showNoFreightMethodsAvailableMessage(event);
    return;
  }

  const emptyAddedFreightSetting = freightMethodSettings.value.find((fms) => fms.id === "");

  if (emptyAddedFreightSetting) {
    return;
  }

  const standardFreightSetting = findStandardFreightSetting();

  if (!standardFreightSetting) {
    return;
  }

  freightMethodSettings.value.push(new AccountSettingFreightMethod(standardFreightSetting));
};

const findStandardFreightSetting = () => {
  return freightMethodSettings.value.find((freightSetting) => freightSetting.isDefault === true);
};

const showNoFreightMethodsAvailableMessage = (event: Event) => {
  freightOverlay.value.toggle(event);
  setTimeout(() => {
    freightOverlay.value.hide();
  }, 2000);
};

const deleteFreightMethodLine = (data: AccountSettingFreightMethod) => {
  const index = freightMethodSettings.value.findIndex((x) => x.id === data.id);
  freightMethodSettings.value.splice(index, 1);
};

onMounted(() => {
  getFreightMethods();
});
</script>

<style lang="scss">
.c-overlay-panel {
  z-index: 111 !important;
}
</style>
