<template>
  <PrimeDialog
    v-model:visible="visible"
    :header="t(`account-plan.dialog.header-add`)"
    :modal="true"
    class="c-add-dialog"
  >
    <div class="col-span-12">
      <div class="grid grid-cols-12 gap-4">
        <div class="field col-span-12">
          <div class="flex items-center">
            <div class="col-span-6">
              <Name v-model:name="accountPlan.accountName" />
            </div>
            <div class="col-span-6">
              <AccountNumber v-model:accountPlanAccountNumber="accountPlan.accountNumber" />
            </div>
          </div>
          <div class="col-span-6">
            <div class="col-span-6">
              <div class="col-span-6">
                <div class="field-checkbox">
                  <label for="account-plan-isActive" data-testid="account-plan-isActive-label">
                    {{ t(`account-plan.dialog.forOutgoing.label`) }}
                  </label>
                  <Checkbox
                    inputId="account-plan-forOutgoing"
                    v-model="accountPlan.forOutgoing"
                    :binary="true"
                    data-testid="c-account-plan-forOutgoing"
                    class="ml-2"
                  />
                </div>
                <div class="field-checkbox">
                  <label for="account-plan-isActive" data-testid="account-plan-isActive-label">
                    {{ t(`account-plan.dialog.forIncoming.label`) }}
                  </label>
                  <Checkbox
                    inputId="account-plan-forIncoming"
                    v-model="accountPlan.forIncoming"
                    :binary="true"
                    data-testid="c-account-plan-forIncoming"
                    class="ml-2"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-between flex-wrap card-container purple-container">
        <div class="flex items-center justify-center">
          <PrimeButton
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            @click="cancel"
            class="p-button-secondary"
          />
        </div>
        <div class="flex items-center justify-center">
          <PrimeButton
            :label="t(`common.add`)"
            data-testid="create-btn"
            @click="createNewAccountPlan"
            class="p-button-success"
            :disabled="isSaving"
          />
        </div>
      </div>
    </template>
  </PrimeDialog>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import useValidate from "@vuelidate/core";
import { useToast } from "primevue/usetoast";

import { useCumulusToast } from "@cumulus/toast";

import Name from "@/components/account-plan/Name.vue";
import AccountNumber from "@/components/account-plan/AccountNumber.vue";
import { useAccountPlanStore } from "@/stores/AccountPlanStore";
import { NewAccountPlan } from "@/models/account-plan/NewAccountPlan";

const { t } = useI18n();
const val = useValidate();
const toast = useCumulusToast(useToast());
const isSaving = ref(false);

const accountPlan = ref(new NewAccountPlan());
const { addAccountPlan } = useAccountPlanStore();
const props = defineProps<{
  showDialog: boolean;
}>();

const createNewAccountPlan = async () => {
  val.value.$touch();
  await val.value.$validate();
  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
      closable: true,
    });
    return;
  }

  try {
    isSaving.value = true;
    await addAccountPlan(accountPlan.value);

    toast.add({
      severity: "success",
      summary: t("account-plan.toast.add-success.summary"),
      detail: t("account-plan.toast.add-success.detail", { name: accountPlan.value.accountName }),
      closable: true,
    });

    visible.value = false;
  } finally {
    isSaving.value = false;
  }
};
const cancel = () => {
  accountPlan.value = new NewAccountPlan();
  visible.value = false;
};

const emit = defineEmits<{
  (e: "update:showDialog", value: boolean): void;
}>();

const visible = computed<boolean>({
  get: () => props.showDialog,
  set: (value) => emit("update:showDialog", value),
});
</script>
