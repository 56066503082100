<template>
  <IconField iconPosition="left">
    <InputIcon class="pi pi-search" />
    <InputText
      id="customerSearchInput"
      ref="searchInputRef"
      v-model="query"
      v-debounce:200="search"
      :placeholder="t('common.search')"
      class="w-full pl-8"
      autocomplete="off"
      data-testid="customer-search-list-input"
    />
  </IconField>
  <CustomerList
    :customers="customers"
    :loading="loading"
    :totalHits="totalHits"
    :pageSize="pageSize"
    :page="page"
    :sortField="sortField"
    :sortOrder="sortOrder"
    @update:sortOrder="onUpdateSortOrder"
    @update:sortField="onUpdateSortField"
    @update:page="onUpdatePage"
    @update:pageSize="onUpdatePageSize"
    @customerSelected="emit('customerSelected', $event)"
  />
</template>

<script setup lang="ts">
import { nextTick, ref } from "vue";
import CustomerList from "./CustomerList.vue";
import { useI18n } from "vue-i18n";
import { type SearchCustomer } from "@/models/search/customer/SearchCustomer";
import { useCustomerSearchService } from "@/api/search/CustomerSearchService";
import { CustomerSearchRequest } from "@/models/search/customer/CustomerSearchRequest";

const { t } = useI18n();
const { customerSearch } = useCustomerSearchService();

const query = ref("");
const totalHits = ref(0);
const page = ref(1);
const pageSize = ref<number>(10);
const sortField = ref("");
const sortOrder = ref(-1);
const customers = ref<SearchCustomer[]>([]);
const loading = ref(false);

const emit = defineEmits<{ (e: "customerSelected", value: SearchCustomer): void }>();

const searchInputRef = ref();
nextTick(() => {
  searchInputRef.value.$el.focus();
});

const onUpdateSortOrder = (value: number) => {
  sortOrder.value = value;
  loading.value = true;
};

const onUpdateSortField = (value: string) => {
  sortField.value = value;
  loading.value = true;
};

const onUpdatePage = (value: number) => {
  page.value = value;
  loading.value = true;
  search();
};

const onUpdatePageSize = (value: number) => {
  pageSize.value = value;
  loading.value = true;
  search();
};

const search = async () => {
  if (query.value.length < 1) {
    customers.value = [];
    totalHits.value = 0;
    return;
  }
  query.value = query.value.length < 1 ? "*" : query.value;

  try {
    loading.value = true;
    const request = new CustomerSearchRequest(query.value);
    request.page = page.value;
    request.pageSize = pageSize.value;
    if (sortField.value === "") {
      sortOrder.value = -1;
    }
    request.sortBy = sortField.value;
    request.sortOrder = sortOrder.value === 1 ? "asc" : "desc";
    const response = await customerSearch(request);

    customers.value = response.customers;
    totalHits.value = response.totalHits;
  } finally {
    loading.value = false;
  }
};
</script>

<style scoped lang="scss">
.customer-panel .p-overlaypanel-content {
  height: auto;
}
</style>
