<template>
  <Dialog
    v-model:visible="visible"
    header="Add currency"
    :modal="true"
    class="c-add-dialog"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
  >
    <div class="formgrid grid grid-cols-12 gap-4 px-8">
      <div class="field col-span-12 md:col-span-6">
        <SelectCurrencyIso
          v-model:currencyIso="currency.currencyIso"
          :currencyIsoOptions="isoOptions"
          @currencyIsoChanged="updateCountryIso"
        />
      </div>

      <div class="field col-span-12 md:col-span-6">
        <div class="flex justify-end items-center h-full">
          <CurrencyState v-model:currencyState="currency.currencyState" />
        </div>
      </div>

      <div class="field col-span-12 md:col-span-6">
        <SelectUnit v-model:unit="currency.unit" />
      </div>

      <div class="field col-span-12 md:col-span-6">
        <BaseCurrency v-model:baseCurrencyIso="currency.baseCurrencyIso" :currencyIsoOptions="isoOptions" />
      </div>

      <div class="field col-span-12">
        <SelectCountry v-model:countryIso="currency.countryIso" :countries="props.countries" />
      </div>

      <div class="field col-span-12">
        <Description v-model:description="currency.description" />
      </div>
    </div>

    <div class="flex justify-end">
      <div v-tooltip.bottom="saveButtonTooltip">
        <Button
          class="c-circular-button c-box-shadow mr-2"
          data-testid="btn-save-currency"
          :disabled="isSaving || !hasUpdates"
          @click="onAddNewCurrency"
        >
          <ProgressSpinner v-if="isSaving" class="c-spinner" />
          <i v-else class="pi pi-check c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.save-changes") }}</span>
        </Button>
      </div>
      <Button
        class="c-circular-button c-box-shadow"
        data-testid="btn-cancel-add-currency"
        :disabled="isSaving"
        @click="emit('update:showAddCurrencyDialog', false)"
      >
        <i class="pi pi-times c-warning-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.cancel") }}</span>
      </Button>
    </div>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import { useAuth } from "@cumulus/event-bus";
import SelectCurrencyIso from "./SelectCurrencyIso.vue";
import CurrencyState from "./CurrencyState.vue";
import BaseCurrency from "./BaseCurrency.vue";
import Description from "./CurrencyDescription.vue";
import SelectUnit from "./SelectUnit.vue";
import SelectCountry from "./SelectCountry.vue";
import { useI18n } from "vue-i18n";
import { type DropdownChangeEvent } from "primevue/dropdown";
import useValidate from "@vuelidate/core";
import { useClientStore } from "@/stores/ClientStore";
import { type Country } from "@/models/country/Country";
import { useCurrencyService } from "@/api/currency/CurrencyService";
import { Currency } from "@/models/currency/Currency";
import isEqual from "lodash.isequal";
import cloneDeep from "lodash.clonedeep";

const props = defineProps<{
  showAddCurrencyDialog: boolean;
  countries: Country[];
}>();

const emit = defineEmits<{
  (e: "update:showAddCurrencyDialog", value: boolean): void;
}>();

const { addCurrency } = useCurrencyService();
const toast = useCumulusToast(useToast());
const { t } = useI18n();
const { getAuthHeaders } = useAuth();
const { getById } = useClientStore();
const val = useValidate();

const currency = ref<Currency>(new Currency());
const initialCurrency = ref<Currency>(new Currency());
const isSaving = ref(false);
const loading = ref(false);

const isoOptions = ref([
  { currencyIso: "NOK", countryIso: "NO" },
  { currencyIso: "SEK", countryIso: "SE" },
  { currencyIso: "DKK", countryIso: "DK" },
  { currencyIso: "GBP", countryIso: "GB" },
  { currencyIso: "USD", countryIso: "US" },
  { currencyIso: "EUR", countryIso: "EU" },
]);

onMounted(async () => {
  try {
    loading.value = true;
    const headers = await getAuthHeaders();

    const client = await getById(headers.clientId);
    currency.value.baseCurrencyIso = client.currencyIso;
    initialCurrency.value = cloneDeep(currency.value);
  } finally {
    loading.value = false;
  }
});

const updateCountryIso = (event: DropdownChangeEvent) => {
  const selectedCountryIso = isoOptions.value.find((c) => c.currencyIso === event.value)?.countryIso;
  const countryIso = props.countries.find((c) => c.iso === selectedCountryIso)?.iso;

  currency.value.countryIso = countryIso ?? "";
};

const visible = computed<boolean>({
  get: () => props.showAddCurrencyDialog,
  set: (value) => emit("update:showAddCurrencyDialog", value),
});

const onAddNewCurrency = async () => {
  val.value.$touch();
  await val.value.$validate();
  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
    });
    return;
  }
  try {
    isSaving.value = true;
    await addCurrency(currency.value);
    toast.add({
      severity: "success",
      summary: t("currency.currency-added-summary"),
      detail: t("currency.currency-added-detail", { iso: currency.value.currencyIso }),
    });
    emit("update:showAddCurrencyDialog", false);
  } finally {
    isSaving.value = false;
  }
};

const hasUpdates = computed(() => {
  return !isEqual(currency.value, initialCurrency.value);
});

const saveButtonTooltip = computed(() => {
  return hasUpdates.value ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});
</script>

<style scoped lang="scss">
.c-box-shadow {
  box-shadow:
    0 0 0 1px rgba(0, 0, 0, 0.1),
    0 2px 4px 0 rgba(0, 0, 0, 0.1);
}

.c-spinner {
  width: 22.83px;
}
</style>
