import { useAuth } from "@cumulus/event-bus";
import { accountGroupApi } from "./AccountGroupApi";
import { useErrorHandler } from "../ErrorHandler";

export function useAccountGroupService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAllAccountGroups = async () => {
    try {
      const authHeaders = await getAuthHeaders();
      return await accountGroupApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getAllAccountGroups,
  };
}
