import { invoiceApi } from "../api/invoice/InvoiceApi";
import { useErrorHandler } from "@/api/ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { Invoice } from "../models/invoice/Invoice";
import { ref } from "vue";
import { defineStore } from "pinia";
import { CreditTotal } from "../models/invoice/CreditTotals";

export const useInvoiceStore = defineStore("invoice-store", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const invoices = ref<Invoice[]>([]);
  const creditTotals = ref<CreditTotal[]>([]);
  const loading = ref(false);

  const getInvoices = async (): Promise<void> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      invoices.value = await invoiceApi.list(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const getCreditTotals = async (invoiceIds: string[]): Promise<void> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      creditTotals.value = await invoiceApi.getCreditTotalsForInvoices(authHeaders, invoiceIds);
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  const getFilteredInvoices = async (customerNumber: string): Promise<void> => {
    try {
      loading.value = true;
      const authHeaders = await getAuthHeaders();
      invoices.value = await invoiceApi.getInvoicesByCustomerNumber(authHeaders, customerNumber);
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  return {
    invoices,
    creditTotals,
    loading,
    getInvoices,
    getCreditTotals,
    getFilteredInvoices,
  };
});
