import { useErrorHandler } from "../ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { documentApi } from "./DocumentApi";
import { PrintDocumentRequest } from "@/models/invoice/PrintDocumentRequest";
import { DocumentType } from "@/models/invoice/DocumentType";

export function useDocument() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getInvoiceUrl = async (invoiceId: string): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.getInvoiceUrl(authHeaders, invoiceId);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getCreditNoteUrl = async (creditNoteUrl: string): Promise<string> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.getCreditNoteUrl(authHeaders, creditNoteUrl);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getAllPrinters = async () => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.getPrinters(authHeaders);
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  const getDefaultPrinterByDocumentType = async (documentType: DocumentType) => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.getDefaultPrinterByDocumentType(authHeaders, documentType);
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  const printDocument = async (request: PrintDocumentRequest) => {
    try {
      const authHeaders = await getAuthHeaders();
      return await documentApi.printDocument(authHeaders, request);
    } catch (error) {
      handleError(error);
      throw error;
    }
  };

  return { getInvoiceUrl, getAllPrinters, getDefaultPrinterByDocumentType, printDocument, getCreditNoteUrl };
}
