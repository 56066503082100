import { httpClient, type AuthHeaders } from "@cumulus/http";
import { Tax } from "@/models/tax/Tax";
import { NewTax } from "@/models/tax/NewTax";

class TaxApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_FINANCE != "undefined" && import.meta.env.VITE_APP_API_FINANCE
        ? `${import.meta.env.VITE_APP_API_FINANCE as string}/taxes`
        : `${import.meta.env.VITE_APP_API_URL as string}/taxes`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Tax[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<Tax> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }) => data);
  }

  public async create(authHeaders: AuthHeaders, newFee: NewTax): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("/", newFee);
  }

  public async update(authHeaders: AuthHeaders, fee: Tax): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/", fee);
  }
}

export const taxApi = new TaxApi();
