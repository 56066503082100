import { FreightMethod } from "@/models/freight-method/FreightMethod";
import { httpClient, AuthHeaders } from "@cumulus/http";

class FreightMethodApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_WAREHOUSE != "undefined" && import.meta.env.VITE_APP_API_WAREHOUSE
        ? `${import.meta.env.VITE_APP_API_WAREHOUSE}/freight-methods`
        : `${import.meta.env.VITE_APP_API_URL as string}/freight-methods`;
  }
  public async getAll(authHeaders: AuthHeaders): Promise<FreightMethod[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }
}
const freightMethodApi = new FreightMethodApi();
export { freightMethodApi };
