<template>
  <label for="account-plan-accountNumber">
    {{ t(`account-plan.dialog.accountNumber.label`) }}
  </label>
  <InputText
    id="account-plan-accountNumber"
    v-model="accountNumber"
    data-testid="account-plan-account-number"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.accountNumber.$error }"
    :placeholder="t(`account-plan.dialog.accountNumber.placeholder`)"
  />
  <small
    v-if="val.accountNumber.$error"
    id="account-plan-accountNumber-help"
    data-testid="account-plan-account-number-error"
    class="p-error"
    >{{ val.accountNumber.$errors[0].$message }}</small
  >
</template>

<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const accountNumber = defineModel<string>("accountNumber", {
  required: true,
});

const isANumber = (value: string) => {
  return !isNaN(Number(value));
};

const rules = {
  accountNumber: {
    required: helpers.withMessage(
      t("common.field-required", { fieldName: t("account-plan.dialog.accountNumber.label") }),
      required,
    ),
    numericAccountNumber: helpers.withMessage(
      t("account-plan.dialog.accountNumber.error", { fieldName: t("account-plan.dialog.accountNumber.label") }),
      isANumber,
    ),
  },
};

const val = useVuelidate(rules, { accountNumber: accountNumber });
</script>
