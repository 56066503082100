<template>
  <div class="sticky bottom-0 bg-[#E7F0F7] dark:bg-surface-900 mt-6">
    <div class="rounded-lg">
      <div class="flex flex-row-reverse flex-wrap mr-6">
        <div class="flex justify-between flex-wrap gap-y-1 gap-x-20">
          <div class="flex flex-col">
            <label class="dark:text-white">{{ t("creditnote.total-amount") }}</label>
            <span class="c-creditnote-order-total-summary" data-testid="credited-amount">{{
              n(totalSum, "decimal")
            }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

defineProps<{
  totalSum: number;
}>();

const { t, n } = useI18n();
</script>
