export const InvoiceLinesColumns = {
  InvoiceDate: "invoiceDate",
  InvoiceNumber: "invoiceNumber",
  OrderNumber: "orderNumber",
  FreightMethodName: "freightMethodName",
  OrderedByCustomerNumber: "orderedBy.customerNumber",
  OrderedByCustomerName: "orderedBy.customerName",
  SumTotalLines: "sumTotalLines",
  Pdf: "pdf",
} as const;
