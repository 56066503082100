import { creditNoteApi } from "../api/credit-note/CreditNoteApi";
import { useErrorHandler } from "@/api/ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { CreditNote } from "../models/credit-note/CreditNote";
import { NewCreditNote } from "../models/credit-note/NewCreditNote";
import { computed, ref } from "vue";
import { defineStore } from "pinia";
import { useI18n } from "vue-i18n";
import { CustomerContact } from "@/models/customer/CustomerContact";
import { Customer } from "@/models/customer/Customer";
import { CustomerType } from "@/models/customer/CustomerType";
import { type ReturnProductInfo } from "@/models/credit-note/ReturnProductInfo";
import { CreditNoteRequest } from "@/models/credit-note/CreditNoteRequest";
import { useToast } from "primevue/usetoast";
import { useCumulusToast } from "@cumulus/toast";

export const useCreditFromInvoicesStore = defineStore("credit-note-from-invoices-store", () => {
  const toast = useCumulusToast(useToast());
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();
  const { t } = useI18n();

  const initialCreditNote = ref<CreditNote>(new CreditNote());
  const currentCreditNote = ref<CreditNote>(new CreditNote());
  const customerContacts = ref<CustomerContact[]>([]);
  const isSaving = ref(false);

  const createCreditNote = async (returnProducts?: ReturnProductInfo[] | undefined) => {
    isSaving.value = true;
    try {
      const authHeaders = await getAuthHeaders();
      const newCreditNote = NewCreditNote.createFromCreditNote(currentCreditNote.value);

      const request = CreditNoteRequest.createFromReturnProducts(newCreditNote, returnProducts);

      await creditNoteApi.create(authHeaders, request);
      toast.add({
        severity: "success",
        summary: t("creditnote.add.toast.success.summary"),
        detail: t("creditnote.add.toast.success.detail"),
        closable: true,
      });
      currentCreditNote.value = new CreditNote();
    } catch (error) {
      await handleError(error);
    } finally {
      isSaving.value = false;
    }
  };

  const setCustomerInfo = (customer: Customer) => {
    if (customer.customerType === CustomerType.Private) {
      currentCreditNote.value.contactPerson = {
        email: customer.email,
        firstName: customer.privateCustomer.firstName,
        lastName: customer.privateCustomer.lastName,
      };
      return;
    }

    if (customer.customerType === CustomerType.Business) {
      const contact = customer.businessCustomer.contacts.find((c) => c.isInvoiceRecipient);

      currentCreditNote.value.contactPerson = {
        email: contact?.email ?? "",
        firstName: contact?.firstName ?? "",
        lastName: contact?.lastName ?? "",
      };
      return;
    }
  };

  const isCreditNoteChanged = computed(() => {
    return !initialCreditNote.value !== !currentCreditNote.value;
  });

  const clearStore = () => {
    initialCreditNote.value = new CreditNote();
    currentCreditNote.value = new CreditNote();
  };

  return {
    currentCreditNote,
    initialCreditNote,
    customerContacts,
    isSaving,
    isCreditNoteChanged,
    createCreditNote,
    clearStore,
    setCustomerInfo,
  };
});
