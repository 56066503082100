import { CurrencyState } from "./CurrencyState";
import { v4 as uuidv4 } from "uuid";

export class Currency {
  id = uuidv4();
  buyRate = 1;
  sellRate = 1;
  unit = 1;
  baseCurrencyIso = "";
  description = "";
  currencyIso = "";
  countryIso = "";
  currencyState: CurrencyState = CurrencyState.Active;
}
