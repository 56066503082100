import { httpClient, type AuthHeaders } from "@cumulus/http";
import { FinanceNumberSeries } from "../../model/finance/FinanceNumberSeries";

class FinanceApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_FINANCE != "undefined" && import.meta.env.VITE_APP_API_FINANCE
        ? `${import.meta.env.VITE_APP_API_FINANCE as string}`
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }
  public async getAllNumberSeries(authHeaders: AuthHeaders): Promise<FinanceNumberSeries[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/finance-number-series")
      .then(({ data }) => data);
  }

  public async updateNumberSeries(authHeaders: AuthHeaders, numberSeries: FinanceNumberSeries): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/finance-number-series", numberSeries);
  }
}

const financeApi = new FinanceApi();
export { financeApi };
