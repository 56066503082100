export class Customer {
  customerNumber = "";
  name = "";
  phoneNumber = "";
  email = "";
  organizationNumber = "";
  addressLines: string[] = [];
  countryIso = "";
  postalCode = "";
  city = "";
}
