import { BusinessCustomer } from "./BusinessCustomer";
import { CustomerAddress } from "./CustomerAddress";
import { CustomerType } from "./CustomerType";
import { PrivateCustomer } from "./PrivateCustomer";

export class Customer {
  id = "";
  name = "";
  customerNumber = "";
  documentLanguageIso = "";
  address: CustomerAddress = new CustomerAddress();
  email = "";
  phoneNumber = "";
  customerType: CustomerType = CustomerType.Private;
  businessCustomer: BusinessCustomer = new BusinessCustomer();
  privateCustomer: PrivateCustomer = new PrivateCustomer();
}
