import { setupI18n } from "@cumulus/locale";
import { type LocaleMessageDictionary, type VueMessageType } from "vue-i18n";
import defaultCommonLocale from "./common/en.json";
import defaultNumberSeriesLocale from "@/number-series/locales/en.json";
import defaultAccountSettingsLocale from "@/locales/account-settings/en.json";
import defaultEceonomyTransferLocale from "@/locales/economy-transfer/en.json";
import defaultCreditNoteLocale from "@/locales/credit-note/en.json";

async function loadMessagesAsync(locale: string): Promise<LocaleMessageDictionary<VueMessageType>> {
  const commonMessages = await import(`../locales/common/${locale}.json`);
  const numberSeriesMessages = await import(`../number-series/locales/${locale}.json`);
  const accountSettingsMessages = await import(`../locales/account-settings/${locale}.json`);
  const economyTransferMessages = await import(`../locales/economy-transfer/${locale}.json`);
  const creditNoteMessages = await import(`../locales/credit-note/${locale}.json`);

  const messages = {
    ...commonMessages.default,
    ...numberSeriesMessages.default,
    ...accountSettingsMessages.default,
    ...economyTransferMessages.default,
    ...creditNoteMessages.default,
  };
  return messages;
}

export const i18n = setupI18n(
  {
    ...defaultCommonLocale,
    ...defaultNumberSeriesLocale,
    ...defaultAccountSettingsLocale,
    ...defaultEceonomyTransferLocale,
    ...defaultCreditNoteLocale,
  } as unknown as string,
  loadMessagesAsync,
);
