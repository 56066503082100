import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/api/ErrorHandler";
import { productApi } from "./ProductApi";
import { NumberSeries } from "@/number-series/model/NumberSeries";
import { NumberSeriesType } from "@/number-series/model/NumberSeriesType";

export function useProductService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getProductNumberSeries = async (): Promise<NumberSeries> => {
    try {
      const authHeaders = await getAuthHeaders();
      const numberSeries = await productApi.getNumberSeries(authHeaders);

      if (numberSeries !== undefined) {
        numberSeries.type = NumberSeriesType.Product;
      }

      return numberSeries;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateProductNumberSeries = async (numberSeries: NumberSeries): Promise<void> => {
    if (numberSeries.type !== NumberSeriesType.Product) {
      throw new Error(`Invalid number series type. Expected Product, got ${numberSeries.type} instead.`);
    }

    try {
      const authHeaders = await getAuthHeaders();
      await productApi.updateNumberSeries(authHeaders, numberSeries);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getProductNumberSeries,
    updateProductNumberSeries,
  };
}
