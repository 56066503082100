import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "../ErrorHandler";
import { goodsoutApi } from "./PickingListNumberSeriesApi";
import { NumberSeries } from "@/number-series/model/NumberSeries";
import { NumberSeriesType } from "@/number-series/model/NumberSeriesType";

export function usePickingListNumberSeriesApi() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getGoodsoutNumberSeries = async (): Promise<NumberSeries> => {
    try {
      const authHeaders = await getAuthHeaders();
      const numberSeries = await goodsoutApi.getNumberSeries(authHeaders);

      if (numberSeries !== undefined) {
        numberSeries.type = NumberSeriesType.PickingList;
      }

      return numberSeries;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateGoodsoutNumberSeries = async (numberSeries: NumberSeries): Promise<void> => {
    if (numberSeries.type !== NumberSeriesType.PickingList) {
      throw new Error(`Invalid number series type. Expected Picking List, got ${numberSeries.type} instead.`);
    }

    try {
      const authHeaders = await getAuthHeaders();
      await goodsoutApi.updateNumberSeries(authHeaders, numberSeries);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getGoodsoutNumberSeries,
    updateGoodsoutNumberSeries,
  };
}
