<template>
  <Dialog
    v-model:visible="visibleComputed"
    :header="t('creditnote.return-stock-dialog.header')"
    :modal="true"
    class="w-full md:w-11/12 lg:w-6/12"
    :closable="false"
  >
    <div class="formgrid grid grid-cols-12 gap-4">
      <div class="col-span-12 field">
        <label for="reason-comment">
          {{ t("creditnote.return-stock-dialog.reason") }}
        </label>
        <InputText
          id="reason-comment"
          v-model="comment"
          type="text"
          data-testid="reason-input"
          class="w-full inputfield"
          :autofocus="true"
        />
      </div>
      <div class="col-span-12">
        <DataTable :value="creditableRowsComputed">
          <Column field="returnToStock" :header="t('creditnote.return-stock-dialog.add')">
            <template #body="{ data, field }">
              <Checkbox v-model="data[field as keyof typeof data]" :binary="true" />
            </template>
          </Column>
          <Column field="productNumber" :header="t('creditnote.headers.productNumber')" />
          <Column field="quantityToCredit" :header="t('creditnote.headers.quantity')" />
          <Column field="listPriceToCredit" :header="t('creditnote.headers.listPriceToCredit')" />
          <Column field="positionNumber" :header="t('creditnote.return-stock-dialog.batchnumber')" />
          <Column field="warehouseId" :header="t('creditnote.return-stock-dialog.warehouse')">
            <template #body="{ data, field, index }">
              <Select
                :id="`warehouse-dropdown-${index}`"
                v-model="data[field as keyof typeof data]"
                :data-testid="`warehouse-dropdown-${index}`"
                :options="warehouseStore.warehouses"
                option-label="name"
                option-value="id"
                class="w-60 c-select"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
    <template #footer>
      <div class="flex justify-between flex-wrap card-container purple-container">
        <div class="flex items-center justify-center mr-4">
          <Button
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            severity="cancel"
            text
            icon="pi pi-times"
            @click="emit('update:visible', false)"
          />
        </div>
        <div class="flex items-center justify-center">
          <Button
            :label="t(`common.save`)"
            :disabled="!creditableRows.some((x) => x.returnToStock)"
            data-testid="update-btn"
            icon="pi pi-check"
            @click="createNewCreditNote"
          />
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { CreditableRowViewModel } from "@/models/credit-note/view-model/CreditableRowViewModel";
import { useWarehouseStore } from "@/stores/WarehouseStore";
import { type ReturnProductInfo } from "@/models/credit-note/ReturnProductInfo";

const props = defineProps<{
  visible: boolean;
  creditableRows: CreditableRowViewModel[];
}>();

const emit = defineEmits<{
  (e: "update:visible", value: boolean): void;
  (e: "saveCreditNote", value: ReturnProductInfo[]): void;
}>();

const { t } = useI18n();

const warehouseStore = useWarehouseStore();
const comment = ref("");

const visibleComputed = computed<boolean>({
  get: () => props.visible,
  set: (value: boolean) => emit("update:visible", value),
});

const creditableRowsComputed = computed(() => {
  const defaultWarehouseId = warehouseStore.warehouses[0].id;
  return props.creditableRows.reduce((acc, row) => {
    if (row.included && !row.isNoReferenceLine && row.quantityToCredit && row.quantityToCredit > 0) {
      row.warehouseId = row.warehouseId ?? defaultWarehouseId;
      acc.push(row);
    }

    return acc;
  }, [] as CreditableRowViewModel[]);
});

const createNewCreditNote = async () => {
  const returnProducts: ReturnProductInfo[] = [];

  for (const row of creditableRowsComputed.value) {
    if (!row.returnToStock) {
      continue;
    }

    returnProducts.push({
      productId: row.productId,
      warehouseId: row.warehouseId,
      quantityToReturn: row.quantityToCredit || 0,
      comment: comment.value,
    });
  }

  emit("saveCreditNote", returnProducts);
};

onMounted(async () => {
  await warehouseStore.getWarehouses();
});
</script>
