<template>
  <InputNumber
    v-model="exchangeRateComputed"
    class="w-full"
    :min="0"
    :minFractionDigits="2"
    :maxFractionDigits="2"
    @input="(event) => emit('exchangeRateUpdated', event.value as unknown as number)"
  />
  <small v-if="val.$error" class="p-error" data-testid="exchange-rate-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script setup lang="ts">
import useVuelidate from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  exchangeRate: number;
}>();

const emit = defineEmits<{
  (e: "update:exchangeRate", value: number): void;
  (e: "exchangeRateUpdated", value: number): void;
}>();

const { t } = useI18n();

const exchangeRateComputed = computed<number>({
  get() {
    return props.exchangeRate;
  },
  set(value) {
    emit("update:exchangeRate", value);
  },
});

const rules = {
  exchangeRate: {
    valueCantBeZero: helpers.withMessage(t("common.validation-error.greater-than-zero"), (value: number) => value > 0),
  },
};

const val = useVuelidate(rules, props);
</script>
