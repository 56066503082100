<template>
  <DataTable
    :value="accountSettings"
    class="c-datatable"
    dataKey="id"
    :autoLayout="true"
    responsiveLayout="scroll"
    selectionMode="single"
    @row-select="onRowSelected"
    @row-dblclick="onRowDblClicked"
    :loading="loading"
    stripedRows
    sortField="name"
    :sortOrder="1"
    :paginator="true"
    :currentPageReportTemplate="
      t('common.current-page-template', {
        first: '{first}',
        last: '{last}',
        totalRecords: '{totalRecords}',
      })
    "
    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
    :rows="pageSize"
    :rowsPerPageOptions="[20, 50, 100]"
    data-testid="c-account-settings-list"
    scrollable
    scrollHeight="75vh"
  >
    <Column field="clientIds" :header="t('account-settings.client')" sortable>
      <template #body="{ data, field }">
        <span>{{ getClientNamesByIds(data[field]) }}</span>
      </template>
    </Column>
    <Column field="economySystem" :header="t('account-settings.economy-system')" />
    <Column field="description" :header="t('account-settings.description')" />

    <template #empty>
      <span>{{ t("account-settings.no-account-settings") }}</span>
    </template>
  </DataTable>

  <PrimeButton
    class="c-circular-button mr-4"
    data-testid="c-account-settings-add-account-settings-button"
    @click="addAccountSettingDialogVisible = true"
  >
    <i class="pi pi-check c-success-button c-circular-icon"></i>
    <span class="px-4">{{ t("common.add") }}</span>
  </PrimeButton>

  <AccountSettingsModal
    v-if="accountSettingsDialogVisible"
    v-model:visibleDialog="accountSettingsDialogVisible"
    :selectedAccountSettingsId="selectedAccountSettingsId"
    :clients="clients"
  />

  <AddAccountSettingModal
    v-if="addAccountSettingDialogVisible"
    v-model:visibleDialog="addAccountSettingDialogVisible"
    :clients="clients"
    @accountSettingsCreated="onAccountSettingsCreated"
  />
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
import { useI18n } from "vue-i18n";
import AccountSettingsModal from "./AccountSettingsModal.vue";
import { DataTableRowDoubleClickEvent, DataTableRowSelectEvent } from "primevue/datatable";
import { storeToRefs } from "pinia";
import { useAccountSettingsService } from "@/api/account-settings/AccountSettingsService";
import { useClientStore } from "@/stores/ClientStore";
import AddAccountSettingModal from "./AddAccountSettingModal.vue";
import { AccountSettingsInfo } from "@/models/account-settings/AccountSettingsInfo";
import { AccountSettingsWebPubSubMessage, EventType } from "@/models/account-settings/AccountSettingsWebPubSubMessage";
import { useAccountSettingsWebSocketStore } from "@/stores/AccountSettingsWebSocketStore";

const accountSettings = ref<AccountSettingsInfo[]>([]);
const selectedAccountSettingsId = ref<string>("");
const accountSettingsDialogVisible = ref(false);
const addAccountSettingDialogVisible = ref(false);

const { getAllAccountSettings } = useAccountSettingsService();
const { setupWebSocket } = useAccountSettingsWebSocketStore();
const clientStore = useClientStore();
const accountSettingWebSocketStore = useAccountSettingsWebSocketStore();
const { clients } = storeToRefs(clientStore);
const { webSocket } = storeToRefs(accountSettingWebSocketStore);

const loading = ref(false);
const pageSize = ref(20);
const { t } = useI18n();

const getClientNamesByIds = (id: string[]) => {
  return clients.value
    .filter((client) => id.includes(client.id))
    .map((client) => client.name)
    .join(", ");
};

const onAccountSettingsCreated = async (id: string) => {
  accountSettings.value = await getAllAccountSettings();
  selectedAccountSettingsId.value = id;
  accountSettingsDialogVisible.value = true;
};

const handleWebSocketMessage = async (event: MessageEvent) => {
  const message = JSON.parse(event.data) as AccountSettingsWebPubSubMessage;
  if (
    (!message.isError && message.eventType === EventType.DefaultAccountSettingsCreated) ||
    message.eventType === EventType.AccountSettingsUpdated ||
    message.eventType === EventType.AccountSettingsDeleted
  ) {
    accountSettings.value = await getAllAccountSettings();
  }
};

onMounted(async () => {
  try {
    loading.value = true;
    accountSettings.value = await getAllAccountSettings();
    await setupWebSocket();

    if (webSocket.value) {
      webSocket.value.addEventListener("message", handleWebSocketMessage);
    }
  } finally {
    loading.value = false;
  }
});

onUnmounted(() => {
  if (webSocket.value) {
    webSocket.value.removeEventListener("message", handleWebSocketMessage);
  }
});

const onRowSelected = (event: DataTableRowSelectEvent) => {
  if (!(event.originalEvent instanceof KeyboardEvent)) {
    return;
  }
  if (event.originalEvent.key !== "Enter") {
    return;
  }
  selectedAccountSettingsId.value = event.data.id;
  accountSettingsDialogVisible.value = true;
};

const onRowDblClicked = (event: DataTableRowDoubleClickEvent) => {
  selectedAccountSettingsId.value = event.data.id;
  accountSettingsDialogVisible.value = true;
};
</script>
