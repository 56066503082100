<template>
  <label for="currency-select-unit">{{ t("currency.unit") }}</label>
  <Select
    id="currency-select-unit"
    v-model="unitComputed"
    :options="unitOptions"
    optionLabel="label"
    optionValue="value"
    class="w-full"
    data-testid="currency-select-unit"
    pt:list:data-testid="currency-select-unit-list"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  unit: number;
}>();

const emit = defineEmits<{
  (e: "update:unit", value: number): void;
}>();

const unitOptions = [
  { label: "1", value: 1 },
  { label: "100", value: 100 },
];

const { t } = useI18n();

const unitComputed = computed<number>({
  get: () => props.unit || 1,
  set: (value: number) => emit("update:unit", value),
});
</script>
