import { defineStore } from "pinia";
import { ref } from "vue";
import { userWarehouseApi } from "../api/warehouse/WarehouseApi";
import { Warehouse } from "../models/credit-note/Warehouse";

export const useWarehouseStore = defineStore("warehouse-store", () => {
  const { getAllWarehouses } = userWarehouseApi();

  const warehouses = ref<Warehouse[]>([]);

  const getWarehouses = async (): Promise<void> => {
    warehouses.value = await getAllWarehouses();
  };

  return {
    warehouses,
    getWarehouses,
  };
});
