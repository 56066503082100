<template>
  <label for="payment-term-short-name">
    {{ t(`payment-term.dialog.short-name.label`) }}
  </label>
  <InputText
    id="payment-term-short-name"
    v-model="shortNameComputed"
    data-testid="payment-term-short-name"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.shortName.$error }"
    :placeholder="t(`payment-term.dialog.short-name.placeholder`)"
  />
  <small
    id="payment-term-short-name-help"
    data-testid="payment-term-short-name-error"
    class="p-error"
    v-if="val.shortName.$error"
    >{{ val.shortName.$errors[0].$message }}</small
  >
</template>

<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  shortName: string;
}>();

const emit = defineEmits<{
  (e: "update:shortName", value: string): void;
}>();

const shortNameComputed = computed<string>({
  get: () => {
    return props.shortName ?? "";
  },
  set: (value) => {
    emit("update:shortName", value);
  },
});

const rules = {
  shortName: {
    required: helpers.withMessage(
      t("common.field-required", { fieldName: t("payment-term.dialog.short-name.label") }),
      required
    ),
  },
};

const val = useVuelidate(rules, props);
</script>
