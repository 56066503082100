import { httpClient, AuthHeaders } from "@cumulus/http";
import { NumberSeries } from "../../model/NumberSeries";

class OrderApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_ORDER != undefined && import.meta.env.VITE_APP_API_ORDER
        ? `${import.meta.env.VITE_APP_API_ORDER as string}/orders/number-series`
        : `${import.meta.env.VITE_APP_API_URL as string}/orders/number-series`;
  }

  public async getNumberSeries(authHeaders: AuthHeaders): Promise<NumberSeries> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }

  public async updateNumberSeries(authHeaders: AuthHeaders, numberSeries: NumberSeries): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/", numberSeries);
  }
}

const orderApi = new OrderApi();
export { orderApi };
