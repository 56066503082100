<template>
  <label for="tax-description">
    {{ t(`tax.dialog.description.label`) }}
  </label>
  <InputText
    id="tax-description"
    v-model="descriptionComputed"
    data-testid="tax-description"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.description.$error }"
    :placeholder="t(`tax.dialog.description.placeholder`)"
  />
  <small v-if="val.description.$error" id="tax-description-help" data-testid="tax-description-error" class="p-error">{{
    val.description.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  description: string;
}>();

const emit = defineEmits<{
  (e: "update:description", value: string): void;
}>();

const descriptionComputed = computed<string>({
  get: () => {
    return props.description ?? "";
  },
  set: (value) => {
    emit("update:description", value);
  },
});

const rules = {
  description: {
    required: helpers.withMessage(
      t("common.field-required", { fieldName: t("tax.dialog.description.label") }),
      required,
    ),
  },
};

const val = useVuelidate(rules, props);
</script>
