<template>
  <ContextMenu ref="cm" :model="menuModel" data-testid="credit-note-context-menu" />

  <CredtiNoteToolbar
    :selectedIcon="selectedIcon"
    :isSortingVisible="isSortingVisible"
    :isChooserVisible="isChooserVisible"
    @openSortList="openSortList"
    @refreshList="onClickCreditNoteRefresh"
    @openColumnChooser="openColumnChooser"
  />
  <div class="card">
    <DataTable
      :key="renderKey as unknown as number"
      v-model:contextMenuSelection="selectedCreditNote"
      :value="creditNotes"
      dataKey="id"
      data-testid="credit-note-search-result"
      :autoLayout="true"
      responsiveLayout="stack"
      breakpoint="999px"
      class="c-invoice-table c-datatable"
      :loading="loading"
      :sortField="sortField"
      :sortOrder="sortOrder"
      :paginator="true"
      :rows="pageSize"
      :rowsPerPageOptions="[50, 100]"
      :currentPageReportTemplate="
        t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      stripedRows
      scrollable
      scrollHeight="75vh"
      :metaKeySelection="false"
      contextMenu
      removableSort
      :resizableColumns="true"
      columnResizeMode="fit"
      :reorderable-columns="true"
      @row-dblclick="onRowDblClick"
      @rowContextmenu="onRowContextMenu"
      @sort="onSort"
      @page="onPage"
      @column-resize-end="onColumnResizeEnd"
      @column-reorder="onColumnReorder"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :key="col.field + '_' + index"
        :field="col.field"
        :header="t(col.header)"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ data, field, index }">
          <template v-if="col.field === CreditNotesLinesColumns.CreditNoteDate">
            <span>{{ d(data.creditNoteDate, "long") }}</span>
          </template>
          <template v-else-if="col.field === CreditNotesLinesColumns.CreditNoteNumber">
            <span>{{ data.creditNoteNumber }}</span>
          </template>
          <template v-else-if="col.field === CreditNotesLinesColumns.CreditNoteLines">
            {{ [...new Set(data.creditNoteLines.map((x: any) => x.invoiceNumber)).values()].join(", ") }}</template
          >
          <template v-else-if="col.field === CreditNotesLinesColumns.OrderedByCustomerNumber">
            <span>{{ data.orderedBy.customerNumber }}</span>
          </template>
          <template v-else-if="col.field === CreditNotesLinesColumns.OrderedByCustomerName">
            <span>{{ data.orderedBy.customerName }}</span>
          </template>
          <template v-else-if="col.field === CreditNotesLinesColumns.TotalSum">
            <span>{{ n(-data.totalSum, "decimal") }}</span>
          </template>
          <template v-else-if="col.field === CreditNotesLinesColumns.CreditComment">
            <span>{{ data.creditComment }}</span>
          </template>
          <template v-else-if="col.field === CreditNotesLinesColumns.Pdf">
            <img :data-testid="'btn-show-pdf-' + index" :src="pdfIcon" @click="downloadPdf(data.id)" />
          </template>
          <template v-else>
            {{ data[field as keyof typeof data] }}
          </template>
        </template>
      </Column>
      <template #loading>
        <span v-if="loading">{{ t("common.loading") }}</span>
      </template>
      <template #empty>
        <span>{{ t("invoice.no-result") }}</span>
      </template>
    </DataTable>
  </div>
  <Popover ref="op" data-testid="sort-list">
    <div class="flex flex-col gap-4 w-[14rem] p-2">
      <div>
        <span class="font-bold block mb-2 underline">{{ t("common.sorting-by") }} </span>
        <ul class="list-none p-2 m-0">
          <li
            v-for="column in creditNotesSearchColumns"
            :key="column.field"
            class="flex px-2 py-3 hover:bg-emphasis cursor-pointer rounded-border"
            :class="[
              'transition-colors duration-200',
              {
                'bg-primary-50 dark:bg-primary-400/10': isSelectedColumn(column),
                'hover:bg-emphasis': !isSelectedColumn(column),
              },
            ]"
            @click="onSelectedRow(column)"
          >
            <div class="flex-1">
              <span class="font-medium">{{ t(column.header) }}</span>
            </div>
            <div class="text-right">
              <i v-if="isSelectedColumn(column)" :class="selectedIcon"></i>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </Popover>

  <PopOverColumnChooser
    ref="chooserRef"
    v-model:selectedColumns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    @resetColumns="resetColumns"
  />

  <CreditNotePrintDialog
    v-if="showPrintModal"
    v-model:visibleDialog="showPrintModal"
    :creditNote="selectedCreditNote"
  />
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onMounted, ref, nextTick, computed } from "vue";
import { useI18n } from "vue-i18n";
import pdfIcon from "@/assets/PDF_file_icon.svg";
import { CreditNotesLinesColumns } from "@/models/search/credit-notes/CreditNotesLinesColumns";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import { useDocument } from "@/api/document/DocumentService";
import {
  type DataTableRowClickEvent,
  type DataTableRowDoubleClickEvent,
  type DataTablePageEvent,
  type DataTableSortEvent,
} from "primevue/datatable";
import CreditNotePrintDialog from "@/components/CreditNotePrintDialog.vue";
import { CreditNote } from "@/models/credit-note/CreditNote";
import { useCreditNoteListStore } from "@/stores/CreditNoteListStore";
import router from "@/router/router";
import { NIL as emptyUuid } from "uuid";
import { useTablePreferences } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";
import CredtiNoteToolbar from "./CreditNoteToolbar.vue";
import PopOverColumnChooser from "./PopOverColumnChooser.vue";

const { t, d, n } = useI18n();
const creditNoteListStore = useCreditNoteListStore();
const { creditNotes, loading } = storeToRefs(creditNoteListStore);
const pageSize = ref<number>(50);
const sortField = ref("");
const sortOrder = ref(-1);
const credits = ref<CreditNote[]>([]);
const searchCreditNoteList = ref();
const currentIconIndex = ref(0);
const op = ref();
const chooserRef = ref();

const emit = defineEmits<{
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
}>();

const creditNotesSearchColumns: DataTableColumn[] = [
  { field: "creditNoteDate", header: "creditnote.list-headers.date-created", sortable: true },
  {
    field: "creditNoteNumber",
    header: "creditnote.list-headers.creditnote-number",
    class: "text-right",
    sortable: true,
  },
  {
    field: "creditNoteLines",
    header: "creditnote.list-headers.invoice-numbers",
    class: "text-right",
    sortable: true,
  },
  {
    field: "orderedBy.customerNumber",
    header: "creditnote.list-headers.customer-number",
    class: "text-right",
    sortable: true,
  },
  {
    field: "orderedBy.customerName",
    header: "creditnote.list-headers.customer-name",
    sortable: true,
  },
  { field: "totalSum", header: "creditnote.list-headers.amount", class: "text-right", sortable: true },
  { field: "creditComment", header: "creditnote.list-headers.comment", sortable: true },
  { field: "pdf", header: "invoice.pdf", class: "c-pdf-icon", sortable: false },
];

const { selectedColumnsComputed, renderKey, onColumnReorder, resetColumns, orderedColumns, onColumnResizeEnd } =
  useTablePreferences("creditNotesSearch", creditNotesSearchColumns, null);

const filteredColumns = computed(() => {
  return orderedColumns.value;
});

const sortBy = [
  { name: "Ascending", icon: "pi pi-sort-amount-up", value: 1 },
  { name: "Descending", icon: "pi pi-sort-amount-down", value: -1 },
];

const selectedIcon = computed(() => {
  return sortBy[currentIconIndex.value].icon;
});

const isSortingVisible = computed<boolean>(() => {
  return op.value?.visible ?? false;
});

const openSortList = (event: Event) => {
  op.value.toggle(event);
};

const openColumnChooser = (event: Event) => {
  chooserRef.value.toggle(event);
};

const updateSortOrder = (order: number) => {
  sortOrder.value = order;
};

const updateSortField = (field: string) => {
  sortField.value = field;
};

const isChooserVisible = computed<boolean>(() => {
  return chooserRef.value?.visible ?? false;
});

const defaultSortField = ref(
  creditNotesSearchColumns.find((column) => column.field === CreditNotesLinesColumns.CreditNoteDate),
);

const isSelectedColumn = (column: DataTableColumn) => {
  return column.field === (defaultSortField.value?.field ?? "");
};

const defaultSortOrder = ref(sortBy[currentIconIndex.value].value);

const onSelectedRow = (row: DataTableColumn) => {
  nextTick(() => {
    focusSearchResult();
    defaultSortOrder.value = defaultSortOrder.value === -1 ? 1 : -1;
    currentIconIndex.value = defaultSortOrder.value === 1 ? 1 : 0;
    defaultSortField.value = row;
    updateSortOrder(-defaultSortOrder.value);
    if (defaultSortField.value) {
      updateSortField(defaultSortField.value.field);
    }
    emit("update:page", 1);
  });
};

const onClickCreditNoteRefresh = async () => {
  loading.value = true;
  await creditNoteListStore.getCreditNotes();
};

onMounted(async () => {
  await creditNoteListStore.getCreditNotes();
});

const menuModel = ref([{ label: t("common.print"), icon: "pi pi-fw pi-print", command: () => togglePrintModal() }]);
const togglePrintModal = () => {
  showPrintModal.value = !showPrintModal.value;
};

const selectedCreditNote = ref();
const showPrintModal = ref(false);

const previouslyFocusRow = ref();
const cm = ref();
const onRowContextMenu = (event: DataTableRowClickEvent) => {
  cm.value.show(event.originalEvent);
};

const toast = useCumulusToast(useToast());
const { getCreditNoteDocumentUrl } = useDocument();
const downloadPdf = async (creditNoteId: string) => {
  try {
    const url = await getCreditNoteDocumentUrl(creditNoteId);

    if (url && url.length > 0) {
      window.open(url, "_blank");
    } else {
      toast.add({
        severity: "error",
        summary: t("common.an-error-occured"),
        detail: t("creditnote.download-link-missing"),
        closable: true,
      });
    }
  } catch {
    // ignored
  }
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  openCreditNote(event.data.id);
};

const openCreditNote = (creditnoteId: string) => {
  creditnoteId != null && creditnoteId !== emptyUuid
    ? router.push({ name: "credit-note-view", params: { id: creditnoteId } })
    : toast.add({
        severity: "warn",
        summary: t("creditnote.add.toast.not-found.summary"),
        detail: t("creditnote.add.toast.not-found.detail"),
        closable: true,
      });
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();

    updateSortOrder(-sortOrder.value);
    updateSortField(sortField);
    emit("update:page", 1);
  });
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const focusSearchResult = () => {
  if (credits.value.length > 0 && searchCreditNoteList.value) {
    if (previouslyFocusRow.value) {
      nextTick(() => {
        previouslyFocusRow.value.focus();
      });
    } else {
      nextTick(() => {
        searchCreditNoteList.value.$el.querySelector("tbody tr:first-of-type").focus();
      });
    }
  }
};
</script>
<style scoped lang="scss">
.c-pdf-icon img {
  display: inline-block;
  width: 1.68rem;
  cursor: pointer;
}
</style>
