import { useErrorHandler } from "../api/ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { taxApi } from "@/api/tax/TaxApi";
import { Tax } from "@/models/tax/Tax";
import { NewTax } from "@/models/tax/NewTax";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useTaxStore = defineStore("tax-store", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const taxes = ref<Tax[]>([]);

  const getTaxes = async () => {
    try {
      const authHeaders = await getAuthHeaders();
      taxes.value = await taxApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const addTax = async (newFee: NewTax): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await taxApi.create(authHeaders, newFee);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateTax = async (newFee: Tax): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await taxApi.update(authHeaders, newFee);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    taxes,
    getTaxes,
    addTax,
    updateTax,
  };
});
