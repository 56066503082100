import { AccountPlan } from "@/models/account-plan/AccountPlan";
import { NewAccountPlan } from "@/models/account-plan/NewAccountPlan";
import { AuthHeaders } from "@cumulus/event-bus";
import { httpClient } from "@cumulus/http";

class AccountPlanApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_FINANCE != undefined && import.meta.env.VITE_APP_API_FINANCE
        ? `${import.meta.env.VITE_APP_API_FINANCE as string}/account-plans`
        : `${import.meta.env.VITE_APP_API_URL as string}/account-plans`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<AccountPlan[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<AccountPlan> {
    return await httpClient(this.uri, authHeaders)
      .get(id)
      .then(({ data }) => data);
  }

  public async create(authHeaders: AuthHeaders, newAccountPlan: NewAccountPlan): Promise<void> {
    return await httpClient(this.uri, authHeaders).post("/", newAccountPlan);
  }

  public async update(authHeaders: AuthHeaders, accountPlan: AccountPlan): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/", accountPlan);
  }
}
export const accountPlanApi = new AccountPlanApi();
