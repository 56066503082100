import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "../ErrorHandler";
import { financeApi } from "./FinanceApi";
import { FinanceNumberSeries } from "@/number-series/model/finance/FinanceNumberSeries";
import { NumberSeries } from "@/number-series/model/NumberSeries";
import { NumberSeriesType } from "@/number-series/model/NumberSeriesType";

export function useFinanceService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getInvoiceNumberSeries = async (): Promise<FinanceNumberSeries> => {
    try {
      const authHeaders = await getAuthHeaders();
      const numberSeries = await financeApi.getAll(authHeaders);

      if (numberSeries !== undefined) {
        numberSeries.invoiceNumberSeries.type = NumberSeriesType.Invoice;
        numberSeries.zeroInvoiceNumberSeries.type = NumberSeriesType.ZeroInvoice;
        numberSeries.creditNoteNumberSeries.type = NumberSeriesType.CreditNote;
      }

      return numberSeries;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateInvoiceNumberSeries = async (invoice: NumberSeries): Promise<void> => {
    if (invoice.type !== NumberSeriesType.Invoice) {
      throw new Error(`Invalid number series type. Expected Invoice, got ${invoice.type} instead.`);
    }

    try {
      const authHeaders = await getAuthHeaders();
      await financeApi.updateInvoiceNumberSeries(authHeaders, invoice);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateZeroInvoiceNumberSeries = async (zeroInvoice: NumberSeries): Promise<void> => {
    if (zeroInvoice.type !== NumberSeriesType.ZeroInvoice) {
      throw new Error(`Invalid number series type. Expected ZeroInvoice, got ${zeroInvoice.type} instead.`);
    }

    try {
      const authHeaders = await getAuthHeaders();
      await financeApi.updateZeroInvoiceNumberSeries(authHeaders, zeroInvoice);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateCreditNoteNumberSeries = async (creditNote: NumberSeries): Promise<void> => {
    if (creditNote.type !== NumberSeriesType.CreditNote) {
      throw new Error(`Invalid number series type. Expected CreditNote, got ${creditNote.type} instead.`);
    }

    try {
      const authHeaders = await getAuthHeaders();
      await financeApi.updateCreditNoteNumberSeries(authHeaders, creditNote);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getInvoiceNumberSeries,
    updateInvoiceNumberSeries,
    updateZeroInvoiceNumberSeries,
    updateCreditNoteNumberSeries,
  };
}
