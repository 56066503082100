export const EconomyTransferLinesColumns = {
  TransferNumber: "transferNumber",
  CustomerNumber: "customerNumber",
  SupplierNumber: "supplierNumber",
  TransferDocumentType: "transferDocumentType",
  DocumentDate: "documentDate",
  PaymentDueDate: "paymentDueDate",
  IsTransfered: "isTransfered",
  CreatedDateTime: "createdDateTime",
} as const;
