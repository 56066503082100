<template>
  <Dialog
    v-model:visible="visibleComputed"
    :header="t('creditnote.return-stock-dialog.header')"
    :modal="true"
    :closable="false"
    class="c-edit-dialog"
  >
    <p>{{ t("creditnote.return-stock-dialog.should-return-stock") }}</p>
    <template #footer>
      <div class="flex justify-between flex-wrap card-container">
        <div class="flex items-center justify-center">
          <Button
            :label="t(`common.cancel`)"
            data-testid="cancel-btn"
            severity="cancel"
            text
            icon="pi pi-times"
            @click="emit('update:visible', false)"
          />
        </div>
        <div class="flex items-center justify-center">
          <Button
            :label="t(`common.yes`)"
            class="mr-4"
            data-testid="open-return-stock-dialog"
            icon="pi pi-check"
            @click="emit('returnInventoryClicked')"
          >
          </Button>
        </div>
        <div class="flex items-center justify-center">
          <Button :label="t(`common.no`)" data-testid="save-credit-note-btn" @click="emit('saveCreditNoteClicked')">
            <ProgressSpinner v-if="isSaving" class="c-spinner" />
          </Button>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();
const isSaving = ref(false);

const props = defineProps<{
  visible: boolean;
}>();

const visibleComputed = computed<boolean>({
  get: () => props.visible,
  set: (value: boolean) => emit("update:visible", value),
});

const emit = defineEmits<{
  (e: "update:visible", value: boolean): void;
  (e: "returnInventoryClicked"): void;
  (e: "saveCreditNoteClicked"): void;
}>();
</script>

<style scoped lang="scss">
.c-spinner {
  width: 7rem;
  height: 1.2rem;
}
</style>
