import { ProductSearchFilters } from "./ProductSearchFilters";

export class ProductSearchRequest {
  query = "";
  page = 1;
  pageSize = 25;
  sortBy = "";
  sortOrder = "asc";
  filters = new ProductSearchFilters();

  constructor(
    query: string,
    page: number,
    pageSize: number,
    sortBy: string,
    sortOrder: string,
    filters: ProductSearchFilters,
  ) {
    this.query = query;
    this.page = page;
    this.pageSize = pageSize;
    this.sortBy = sortBy;
    this.sortOrder = sortOrder;
    this.filters = filters;
  }
}
