import { Customer } from "@/models/customer/Customer";
import { httpClient, AuthHeaders } from "@cumulus/http";

class CustomerApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_ORDER != undefined && import.meta.env.VITE_APP_API_ORDER
        ? import.meta.env.VITE_APP_API_ORDER + ""
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }

  public async getById(authHeaders: AuthHeaders, customerId: string): Promise<Customer> {
    return await httpClient(this.uri, authHeaders)
      .get(`/customers/${customerId}`)
      .then(({ data }) => data);
  }
}

export const customerApi = new CustomerApi();
