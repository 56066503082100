<template>
  <FloatLabel variant="on">
    <Textarea
      v-model="comment"
      rows="2"
      class="mb-3"
      :disabled="disabled"
      dataTestId="creditnote-comment"
      :autoResize="false"
      :fluid="true"
      style="overflow-y: auto"
    />
    <label for="comment">
      {{ t(`creditnote.comment`) }}
    </label>
  </FloatLabel>
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const comment = defineModel<string>("comment", {
  required: true,
});

defineProps<{
  disabled?: boolean;
}>();
</script>
