import { creditNoteApi } from "../api/credit-note/CreditNoteApi";
import { useErrorHandler } from "@/api/ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { ref } from "vue";
import { defineStore } from "pinia";
import { CreditNote } from "@/models/credit-note/CreditNote";

export const useCreditNoteListStore = defineStore("credit-note-list-store", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const creditNotes = ref<CreditNote[]>([]);
  const loading = ref(false);

  const getCreditNotes = async () => {
    loading.value = true;
    try {
      const authHeaders = await getAuthHeaders();
      creditNotes.value = await creditNoteApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  return {
    creditNotes,
    loading,
    getCreditNotes,
  };
});
