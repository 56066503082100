import { type Ref, onBeforeUnmount, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import { onBeforeRouteLeave, useRouter } from "vue-router";

export function useCreditNoteRouteService(isCreditNoteChanged: Ref<boolean>, clearStore: () => void) {
  const confirmedDiscard = ref(false);
  const previouslyFocusedInput = ref<HTMLInputElement | null>(null);
  const unsavedChangesDialogVisible = ref(false);

  const router = useRouter();
  const { t } = useI18n();

  const handleKeydown = (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      if (unsavedChangesDialogVisible.value) {
        stayOnPage();
      } else {
        onCancel();
      }
    } else if (event.ctrlKey && event.key === "i" && unsavedChangesDialogVisible.value) {
      routeToInvoiceList();
    }
  };

  const onCancel = () => {
    previouslyFocusedInput.value = document.activeElement as HTMLInputElement;
    if (isCreditNoteChanged.value) {
      unsavedChangesDialogVisible.value = true;
    } else {
      routeToInvoiceList();
    }
  };

  const stayOnPage = () => {
    unsavedChangesDialogVisible.value = false;
    if (previouslyFocusedInput.value) {
      previouslyFocusedInput.value.focus();
    }
  };

  const routeToInvoiceList = () => {
    confirmedDiscard.value = true;
    router.push({ name: "invoice" });
    clearStore();
  };

  const routeToCreditNoteList = () => {
    confirmedDiscard.value = true;
    router.push({ name: "credit-note-list" });
    clearStore();
  };

  onMounted(() => {
    document.addEventListener("keydown", handleKeydown);
  });

  onBeforeUnmount(() => {
    document.removeEventListener("keydown", handleKeydown);
  });

  onBeforeRouteLeave((_to, _from, next) => {
    if (isCreditNoteChanged.value && !confirmedDiscard.value) {
      unsavedChangesDialogVisible.value = true;
      next(false);
    } else {
      next();
    }
  });

  window.addEventListener("beforeunload", (e) => {
    if (isCreditNoteChanged.value) {
      e.preventDefault();
      e.returnValue = t("common.unsaved-changes-header");
    }
  });

  return {
    routeToInvoiceList,
    onCancel,
    stayOnPage,
    routeToCreditNoteList,
    unsavedChangesDialogVisible,
  };
}
