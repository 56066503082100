<template>
  <FloatLabelDropdownPanel
    id="customer-search-input"
    :selectedItemName="selectedCustomerName"
    :label="t('creditnote.customer.label')"
    :selectLabel="t('creditnote.customer.search')"
    dataTestId="customer-select-input"
    :disabled="disabled"
    :class="{ 'p-disabled': disabled }"
    :popoverVisible="popoverVisible"
    @toggleDropdownPanel="showCustomerPanel"
  />
  <Popover
    id="customer_overlay_panel"
    ref="customerPanelRef"
    appendTo="body"
    :dismissable="true"
    :breakpoints="{ '960px': '75vw', '640px': '100vw', '90000px': '800px' }"
    class="customer-panel"
    @keydown.esc.stop="hideOverlayPanel"
    @show="popoverVisible = true"
    @hide="popoverVisible = false"
  >
    <CustomerSearch @customerSelected="onCustomerSelected" />
  </Popover>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import CustomerSearch from "./CustomerSearch.vue";
import type { SearchCustomer } from "@/models/search/customer/SearchCustomer";

const { t } = useI18n();

defineProps<{
  disabled?: boolean;
  selectedCustomerName: string;
}>();

const emit = defineEmits<{
  (e: "customerSelected", value: SearchCustomer): void;
}>();

const onCustomerSelected = (customer: SearchCustomer) => {
  customerPanelRef.value.hide();
  emit("customerSelected", customer);
};

const hideOverlayPanel = () => customerPanelRef.value.hide();

const customerPanelRef = ref();
const showCustomerPanel = (event: Event) => {
  customerPanelRef.value.toggle(event);
};

const popoverVisible = ref(false);
</script>
