<template>
  <DataTable
    :value="creditableRows"
    class="c-datatable"
    data-testId="creditable-rows"
    :stripedRows="true"
    :loading="loading"
  >
    <ColumnGroup type="header">
      <Row>
        <template v-for="column in columns" :key="column.field">
          <Column :field="column.field" />
        </template>

        <Column
          headerClass="text-center"
          :header="t('creditnote.headers.prev-credited')"
          class="w-28 pb-0 bg-[#e7f0f7] dark:bg-primary-950 rounded-t-lg"
          :colspan="2"
        />
      </Row>
      <Row>
        <template v-for="column in columns" :key="column.field">
          <Column :field="column.field" :header="column.header" />
        </template>
        <Column
          field="previouslyCreditedQuantity"
          :header="t('creditnote.headers.quantity')"
          class="bg-[#e7f0f7] dark:bg-primary-950"
        />
        <Column
          field="previouslyCreditedSum"
          :header="t('creditnote.headers.sum')"
          class="bg-[#e7f0f7] dark:bg-primary-950"
        />
      </Row>
    </ColumnGroup>
    <Column field="included">
      <template #header>
        <Checkbox v-model="allIncluded" :binary="true" />
      </template>
      <template #body="{ data, field }">
        <Checkbox
          v-model="data[field as keyof typeof data]"
          :binary="true"
          @update:modelValue="if ($event) emit('initializeValuesToCredit', data);"
        />
      </template>
    </Column>
    <Column field="invoiceNumber" class="w-40" :header="t('creditnote.headers.invoiceNumber')">
      <template #body="{ data, field }">
        <span :class="{ 'text-center': !data[field as keyof typeof data] }" class="w-40">
          {{ data[field as keyof typeof data] || "" }}
        </span>
      </template>
    </Column>
    <Column field="productNumber" class="w-40" :header="t('creditnote.headers.productNumber')">
      <template #body="{ data, field }">
        {{
          data[field as keyof typeof data]
            ? data[field as keyof typeof data]
            : data.freightMethodId
              ? t("creditnote.freight")
              : ""
        }}
      </template>
    </Column>
    <Column field="name" :header="t('creditnote.headers.productName')">
      <template #body="{ data, field, index }">
        <InputText
          v-if="data.isNoReferenceLine"
          v-model="data[field as keyof typeof data]"
          :data-testid="`no-reference-input-${index}`"
        />
        <span v-else>{{ data[field as keyof typeof data] }}</span>
      </template>
    </Column>

    <Column field="quantity" class="text-right w-12">
      <template #body="{ data, field }">
        {{ data[field as keyof typeof data] !== null ? n(data[field as keyof typeof data], "decimal") : "" }}
      </template>
    </Column>

    <Column field="listPrice" class="text-right w-32">
      <template #body="{ data, field }">
        {{ data[field as keyof typeof data] !== null ? n(data[field as keyof typeof data], "decimal") : "" }}
      </template>
    </Column>
    <Column field="invoicedSum" class="text-right w-40">
      <template #body="{ data, field }">
        {{ data[field as keyof typeof data] ? n(data[field as keyof typeof data], "decimal") : "" }}
      </template>
    </Column>

    <Column field="quantityToCredit" class="w-20 p-0">
      <template #body="{ data, field, index }">
        <InputNumber
          v-show="data.included && data[field as keyof typeof data] !== null"
          v-model="data[field as keyof typeof data]"
          :min="0"
          :max="
            data.isNoReferenceLine || data.invoiceId === ''
              ? Number.MAX_SAFE_INTEGER
              : data.quantity - (findExistingCredit(data.invoiceId, data.positionNumber)?.quantity ?? 0)
          "
          :step="1"
          :locale="locale"
          mode="decimal"
          :data-testid="`quantity-to-credit-${index}`"
          inputClass="w-24 text-right"
          @update:modelValue="updatePriceAndSum(data, $event, data.listPriceToCredit)"
        />
      </template>
    </Column>
    <Column field="listPriceToCredit" class="w-32 p-0">
      <template #body="{ data, field, index }">
        <InputNumber
          v-show="data.included && data[field as keyof typeof data] !== null"
          v-model="data[field as keyof typeof data]"
          :min="0"
          :max="
            data.isNoReferenceLine || data.invoiceId === ''
              ? Number.MAX_SAFE_INTEGER
              : (data.invoicedSum - data.previouslyCreditedSum) / (data.quantityToCredit || 1)
          "
          :step="0.01"
          :min-fraction-digits="2"
          :max-fraction-digits="2"
          :locale="locale"
          mode="decimal"
          :data-testid="`price-to-credit-${index}`"
          inputClass="w-32 text-right"
          @update:modelValue="updatePriceAndSum(data, data.quantityToCredit, $event)"
        />
      </template>
    </Column>

    <Column field="sumToCredit" class="w-32 text-right">
      <template #body="{ data, field, index }">
        <div :data-testid="`sum-to-credit-${index}`">
          {{
            data.included && data[field as keyof typeof data] !== null
              ? n(data[field as keyof typeof data], "decimal")
              : ""
          }}
        </div>
      </template>
    </Column>
    <Column field="previouslyCreditedQuantity" class="w-24 text-right">
      <template #body="{ data, field }">
        {{ data[field as keyof typeof data] ? n(data[field as keyof typeof data], "decimal") : "" }}
      </template>
    </Column>
    <Column field="previouslyCreditedSum" class="w-32 text-right">
      <template #body="{ data, field }">
        {{ data[field as keyof typeof data] ? n(data[field as keyof typeof data], "decimal") : "" }}
      </template>
    </Column>

    <template #empty> {{ t("creditnote.no-creditable-rows") }} </template>
  </DataTable>
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";
import { CreditableRow } from "@/models/credit-note/CreditableRow";
import { useInvoiceStore } from "@/stores/InvoiceStore";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";

const { t, n, locale } = useI18n();
const invoiceStore = useInvoiceStore();
const columns = [
  { field: "included", style: { width: "50px" } },
  { field: "invoiceNumber", header: t("creditnote.headers.invoiceNumber") },
  {
    field: "productNumber",
    header: t("creditnote.headers.productNumber"),
  },
  { field: "name", header: t("creditnote.headers.productName"), style: { "min-width": "150px" } },
  { field: "quantity", header: t("creditnote.headers.quantity") },
  { field: "listPrice", header: t("creditnote.headers.listPrice") },
  { field: "invoicedSum", header: t("creditnote.headers.invoicedSum") },
  { field: "quantityToCredit", header: t("creditnote.headers.quantityToCredit") },
  { field: "listPriceToCredit", header: t("creditnote.headers.listPriceToCredit") },
  { field: "sumToCredit", header: t("creditnote.headers.sumToCredit") },
];
const props = defineProps<{
  creditableRows: CreditableRow[];
  loading?: boolean;
}>();

const emit = defineEmits<{
  (e: "initializeValuesToCredit", value: CreditableRow): void;
}>();

const allIncluded = computed<boolean>({
  get() {
    return props.creditableRows.every((row) => row.included);
  },
  set(value) {
    props.creditableRows.forEach((row) => {
      row.included = value;
      if (value) emit("initializeValuesToCredit", row);
    });
  },
});

const findExistingCredit = (invoiceId: string, positionNumber: number) => {
  const existingCreditLines = invoiceStore.creditTotals.find((x) => x.id === invoiceId)?.creditedInvoiceLines;
  const existing = existingCreditLines?.find((x) => x.positionNumber === positionNumber);
  return existing;
};

const updatePriceAndSum = (row: CreditableRow, quantity: number | null, listPrice: number | null) => {
  row.sumToCredit = (quantity || 1) * (listPrice ?? 0);
  const max =
    row.isNoReferenceLine || row.invoiceId === ""
      ? Number.MAX_SAFE_INTEGER
      : row.invoicedSum - row.previouslyCreditedSum;
  if (row.sumToCredit > max) row.sumToCredit = max;
  if ((row.quantityToCredit || 1) * (row.listPriceToCredit ?? 0) > max) {
    row.listPriceToCredit = max / (row.quantityToCredit || 1);
  }
};
</script>
