import { Component } from "vue";
import { RouteRecordRaw } from "vue-router";

export const mainRoutes: Array<RouteRecordRaw> =
  import.meta.env.VITE_APP_STANDALONE === "true" && !import.meta.env.VITE_APP_TESTS
    ? [
        {
          name: "Pages",
          path: "/",
          component: (): Component => import("./Routes.vue"),
        },
        {
          name: "oauth2-redirect",
          path: "/oauth2-redirect",
          component: (): Component => import("../components/b2c/OAuth2Redirect.vue"),
        },
        {
          name: "Logout",
          path: "/logout",
          component: (): Component => import("../components/b2c/Logout.vue"),
        },
        {
          path: "/failed",
          name: "Failed",
          component: (): Component => import("../components/b2c/Failed.vue"),
        },
      ]
    : [];

export const invoiceRoutes: RouteRecordRaw[] = [
  {
    name: "invoice",
    path: "/finance/invoice",
    component: (): Component => import("@/views/InvoiceSearch.vue"),
    meta: {
      authRequired: true,
      title: "invoice.header",
    },
  },
];

export const creditNoteRoutes: RouteRecordRaw[] = [
  {
    name: "credit-note-create",
    path: "/finance/credit-note/create",
    component: (): Component => import("@/views/CreditNoteCreate.vue"),
    meta: {
      authRequired: true,
      title: "creditnote.add.title",
    },
  },
  {
    name: "credit-note-create-from-invoices",
    path: "/finance/credit-note/create-from-invoices",
    component: (): Component => import("@/views/CreditNoteCreateFromInvoices.vue"),
    meta: {
      authRequired: true,
      title: "creditnote.add.title",
    },
  },
  {
    name: "credit-note-list",
    path: "/finance/credit-note",
    component: (): Component => import("@/views/CreditNoteSearch.vue"),
    meta: {
      authRequired: true,
      title: "creditnote.header",
    },
  },
  {
    name: "credit-note-view",
    path: "/finance/credit-note/view/:id",
    component: (): Component => import("@/views/CreditNoteView.vue"),
    meta: {
      authRequired: true,
      title: "creditnote.header",
    },
  },
];

export const settingsRoutes: RouteRecordRaw[] = [
  {
    name: "settings",
    path: "/finance/settings",
    component: (): Component => import("@/views/Settings.vue"),
    meta: {
      authRequired: true,
      title: "settings.header",
    },
  },
];

export const economyTransferRoutes: RouteRecordRaw[] = [
  {
    name: "economy-transfers",
    path: "/finance/transfer",
    component: (): Component => import("@/views/EconomyTransfers.vue"),
    meta: {
      authRequired: true,
      title: "economy-transfer.title",
    },
  },
  {
    name: "economy-transfer-view",
    path: "/finance/transfer/:id",
    component: (): Component => import("@/views/EconomyTransferView.vue"),
    meta: {
      authRequired: true,
      title: "economy-transfer.title",
    },
  },
];
