<template>
  <div class="c-number-series grid grid-cols-12 gap-4">
    <div class="col-span-6" v-if="loadFailed">
      {{ t("number-series.load-failed", { errorReason: errorReason }) }}
    </div>

    <div class="col-span-12 lg:col-span-6 lg:border-r lg:pr-4 mb-4">
      <NumberSeriesList
        :loading="invoiceLoading"
        :numberSeries="invoiceNumberSeriesGroup"
        @saveNumberSeries="updateNumberSeries"
      />
    </div>

    <div class="col-span-12 lg:col-span-6 mb-4">
      <NumberSeriesList
        :loading="goodsLoading"
        :numberSeries="goodsNumberSeriesGroup"
        @saveNumberSeries="updateNumberSeries"
      />
    </div>

    <div class="col-span-12 lg:col-span-6 lg:border-r lg:pr-4 mb-4">
      <NumberSeriesList
        :loading="customerLoading"
        :numberSeries="customerNumberSeriesGroup"
        @saveNumberSeries="updateNumberSeries"
      />
    </div>

    <div class="col-span-12 lg:col-span-6 mb-4">
      <NumberSeriesList
        :loading="productLoading"
        :numberSeries="productNumberSeriesGroup"
        @saveNumberSeries="updateNumberSeries"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { computed, onMounted, ref } from "vue";
import { NumberSeries } from "@/number-series/model/NumberSeries";
import { useFinanceService } from "@/number-series/api/finance/FinanceService";
import { useProductService } from "@/number-series/api/product/ProductService";
import { useSupplierService } from "@/number-series/api/supplier/SupplierService";
import { usePurchaseService } from "@/number-series/api/purchase/PurchaseService";
import { useCustomerService } from "@/number-series/api/customer/CustomerService";
import { useOrderService } from "@/number-series/api/order/OrderService";
import { usePickingListNumberSeriesApi } from "@/number-series/api/picking-list/PickingListNumberSeriesService";

import NumberSeriesList from "./NumberSeriesList.vue";
import { NumberSeriesType } from "@/number-series/model/NumberSeriesType";

const { t } = useI18n();

const loadFailed = ref(false);
const errorReason = ref("");

const invoiceLoading = ref(true);
const productLoading = ref(true);
const customerLoading = ref(true);
const goodsLoading = ref(true);

const invoiceNumberSeries = ref<NumberSeries>(new NumberSeries());
const zeroInvoiceNumberSeries = ref<NumberSeries>(new NumberSeries());
const creditNoteNumberSeries = ref<NumberSeries>(new NumberSeries());
const productNumberSeries = ref<NumberSeries>(new NumberSeries());
const supplierNumberSeries = ref<NumberSeries>(new NumberSeries());
const purhcaseNumberSeries = ref<NumberSeries>(new NumberSeries());
const customerNumberSeries = ref<NumberSeries>(new NumberSeries());
const orderNumberSeries = ref<NumberSeries>(new NumberSeries());
const pickingListNumberSeries = ref<NumberSeries>(new NumberSeries());

const invoiceNumberSeriesGroup = computed(() => [
  invoiceNumberSeries.value,
  zeroInvoiceNumberSeries.value,
  creditNoteNumberSeries.value,
]);
const customerNumberSeriesGroup = computed(() => [customerNumberSeries.value, orderNumberSeries.value]);
const productNumberSeriesGroup = computed(() => [
  productNumberSeries.value,
  purhcaseNumberSeries.value,
  supplierNumberSeries.value,
]);
const goodsNumberSeriesGroup = computed(() => [pickingListNumberSeries.value]);

const {
  getInvoiceNumberSeries,
  updateInvoiceNumberSeries,
  updateZeroInvoiceNumberSeries,
  updateCreditNoteNumberSeries,
} = useFinanceService();

const { getProductNumberSeries, updateProductNumberSeries } = useProductService();
const { getSupplierNumberSeries, updateSupplierNumberSeries } = useSupplierService();
const { getPurchaseNumberSeries, updatePurchaseNumberSeries } = usePurchaseService();
const { getCustomerNumberSeries, updateCustomerNumberSeries } = useCustomerService();
const { getOrderNumberSeries, updateOrderNumberSeries } = useOrderService();
const { getGoodsoutNumberSeries, updateGoodsoutNumberSeries } = usePickingListNumberSeriesApi();

const fetchNumberSeries = async () => {
  const handleError = (error: unknown) => {
    if (error instanceof Error) errorReason.value = error.message;
    loadFailed.value = true;
  };

  let invoiceLoads = 0;
  const handleInvoiceLoading = () => {
    invoiceLoads++;
    if (invoiceLoads >= invoiceNumberSeriesGroup.value.length) {
      invoiceLoading.value = false;
    }
  };

  let productLoads = 0;
  const handleProductLoading = () => {
    productLoads++;
    if (productLoads >= productNumberSeriesGroup.value.length) {
      productLoading.value = false;
    }
  };

  let customerLoads = 0;
  const handleCustomerLoading = () => {
    customerLoads++;
    if (customerLoads >= customerNumberSeriesGroup.value.length) {
      customerLoading.value = false;
    }
  };

  let goodsLoads = 0;
  const handleGoodsLoading = () => {
    goodsLoads++;
    if (goodsLoads >= goodsNumberSeriesGroup.value.length) {
      goodsLoading.value = false;
    }
  };

  getInvoiceNumberSeries()
    .then((finance) => {
      invoiceNumberSeries.value = finance.invoiceNumberSeries;
      zeroInvoiceNumberSeries.value = finance.zeroInvoiceNumberSeries;
      creditNoteNumberSeries.value = finance.creditNoteNumberSeries;
      handleInvoiceLoading();
      handleInvoiceLoading();
      handleInvoiceLoading();
    })
    .catch(handleError);

  getProductNumberSeries()
    .then((product) => {
      productNumberSeries.value = product;
      handleProductLoading();
    })
    .catch(handleError);

  getSupplierNumberSeries()
    .then((supplier) => {
      supplierNumberSeries.value = supplier;
      handleProductLoading();
    })
    .catch(handleError);

  getPurchaseNumberSeries()
    .then((purchase) => {
      purhcaseNumberSeries.value = purchase;
      handleProductLoading();
    })
    .catch(handleError);

  getCustomerNumberSeries()
    .then((customer) => {
      customerNumberSeries.value = customer;
      handleCustomerLoading();
    })
    .catch(handleError);

  getOrderNumberSeries()
    .then((order) => {
      orderNumberSeries.value = order;
      handleCustomerLoading();
    })
    .catch(handleError);

  getGoodsoutNumberSeries()
    .then((goodsout) => {
      pickingListNumberSeries.value = goodsout;
      handleGoodsLoading();
    })
    .catch(handleError);
};

const updateNumberSeries = async (value: NumberSeries) => {
  switch (value.type) {
    case NumberSeriesType.Invoice:
      await updateInvoiceNumberSeries(value);
      invoiceNumberSeries.value = value;
      break;

    case NumberSeriesType.ZeroInvoice:
      await updateZeroInvoiceNumberSeries(value);
      zeroInvoiceNumberSeries.value = value;
      break;

    case NumberSeriesType.CreditNote:
      await updateCreditNoteNumberSeries(value);
      creditNoteNumberSeries.value = value;
      break;

    case NumberSeriesType.Product:
      await updateProductNumberSeries(value);
      productNumberSeries.value = value;
      break;

    case NumberSeriesType.Supplier:
      await updateSupplierNumberSeries(value);
      supplierNumberSeries.value = value;
      break;

    case NumberSeriesType.Purchase:
      await updatePurchaseNumberSeries(value);
      purhcaseNumberSeries.value = value;
      break;

    case NumberSeriesType.Customer:
      await updateCustomerNumberSeries(value);
      customerNumberSeries.value = value;
      break;

    case NumberSeriesType.Order:
      await updateOrderNumberSeries(value);
      orderNumberSeries.value = value;
      break;

    case NumberSeriesType.PickingList:
      await updateGoodsoutNumberSeries(value);
      pickingListNumberSeries.value = value;
      break;
  }
};

onMounted(fetchNumberSeries);
</script>

<style scoped lang="scss">
/*
.c-number-series {
  .c-series-group {
    @media screen and (min-width: 1600px) {
      width: 50%;
    }
  }
  .c-border-right {
    @media screen and (min-width: 1600px) {
      border-right: 4px solid var(--list-row-bg);
      padding-right: 2rem;
    }
  }
}
  */
</style>
