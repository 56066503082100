<template>
  <div class="flex flex-wrap mt-3 pl-4">
    <div class="min-w-full lg:min-w-0 lg:w-2/12 xl:w-4/12 content-center">
      <Button
        id="close-creditnote"
        icon="pi pi-arrow-left"
        data-testid="close-creditnote"
        rounded
        size="small"
        variant="text"
        @click="routeToCreditnotesList"
      />
      <div class="inline-block ml-4 text-lg">
        <span class="text-slate-800">{{ t("common.cancel") }}</span>
      </div>
    </div>
    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-4 lg:mb-0 text-center"></div>
    <div class="min-w-full lg:min-w-0 lg:w-5/12 xl:w-4/12 mb-2 lg:mb-0 text-center lg:text-right lg:pr-5"></div>
  </div>

  <div class="c-credit-note">
    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12 xl:col-span-6">
        <Card>
          <template #content>
            <div class="h-full">
              <div class="grid grid-cols-12 gap-4">
                <div class="col-span-12">
                  <div class="flex justify-between flex-wrap items-center">
                    <div class="pl-8">
                      <b>{{ t("creditnote.header") }}: </b>
                      <span data-testid="creditnote-number">{{ currentCreditNote.creditNoteNumber }}</span>
                    </div>
                    <div class="pr-2">
                      <b>{{ t("creditnote.list-headers.date-created") }}: </b>
                      <span data-testid="creditnote-registered">{{ d(currentCreditNote.creditNoteDate) }}</span>
                    </div>
                  </div>
                </div>
                <div class="col-span-12 xl:col-span-6">
                  <div class="col-span-12">
                    <ContactPerson
                      v-model:contactPerson="currentCreditNote.contactPerson"
                      :customerContacts="[]"
                      :customerType="CustomerType.Private"
                      :disabled="true"
                    />
                  </div>
                  <div class="col-span-12">
                    <ContactEmail v-model:email="currentCreditNote.contactPerson.email" :disabled="true" />
                  </div>
                </div>
                <div class="col-span-12 xl:col-span-6">
                  <CustomerInfo :customer="currentCreditNote.orderedBy" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>

      <div class="col-span-12 xl:col-span-6">
        <Card>
          <template #content>
            <div class="h-full">
              <div class="formgrid grid grid-cols-12 gap-4">
                <div class="field col-span-12">
                  <CreditReference v-model:creditReference="currentCreditNote.creditReference" :disabled="true" />
                </div>
                <div class="field col-span-12">
                  <Comment v-model:comment="currentCreditNote.creditComment" :disabled="true" />
                </div>
              </div>
            </div>
          </template>
        </Card>
      </div>

      <div class="col-span-12 xl:col-span-12">
        <Card v-if="currentCreditNote.creditNoteLines">
          <template #content>
            <div>
              <DataTable :value="currentCreditNote.creditNoteLines" class="c-datatable" :stripedRows="true">
                <Column field="included" style="width: 50px">
                  <template #header>
                    <Checkbox v-model="checked" :binary="true" disabled />
                  </template>
                  <template #body>
                    <Checkbox v-model="checked" :binary="true" disabled />
                  </template>
                </Column>

                <Column field="productNumber" class="w-40" :header="t('creditnote.headers.productNumber')" />
                <Column field="productName" :header="t('creditnote.headers.productName')">
                  <template #body="{ data, field }">
                    {{ data.isNoReferenceLine ? data.noReference : data[field as keyof typeof data] }}
                  </template>
                </Column>
                <Column field="invoiceNumber" class="text-right w-40" :header="t('creditnote.headers.invoiceNumber')" />
                <Column field="quantity" class="text-right w-12" :header="t('creditnote.headers.quantity')" />
                <Column field="price" class="text-right w-32" :header="t('creditnote.headers.listPrice')">
                  <template #body="{ data, field }">
                    {{ n(data[field as keyof typeof data], "decimal") }}
                  </template>
                </Column>
                <Column field="sumLine" class="text-right w-32" :header="t('creditnote.headers.sumToCredit')">
                  <template #body="{ data, field }">
                    {{ n(data[field as keyof typeof data], "decimal") }}
                  </template>
                </Column>
              </DataTable>
              <CreditNoteFooter :totalSum="currentCreditNote.totalSum" />
            </div>
          </template>
        </Card>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import router from "@/router/router";
import { useRoute } from "vue-router";
import CustomerInfo from "@/components/credit-note/CustomerInfo.vue";
import Comment from "@/components/credit-note/Comment.vue";
import CreditReference from "@/components/credit-note/CreditReference.vue";
import ContactPerson from "@/components/credit-note/ContactPerson.vue";
import { useCreditNoteViewStore } from "@/stores/CreditNoteViewStore";
import { storeToRefs } from "pinia";
import ContactEmail from "@/components/credit-note/ContactEmail.vue";
import CreditNoteFooter from "@/components/credit-note/CreditNoteFooter.vue";
import { CustomerType } from "@/models/customer/CustomerType";

const { t, n, d } = useI18n();
const checked = ref(true);

const creditNoteId = ref("");
const route = useRoute();
const creditNoteViewStore = useCreditNoteViewStore();
const { currentCreditNote } = storeToRefs(creditNoteViewStore);

const routeToCreditnotesList = () => {
  router.push({ name: "credit-note-list" });
};

const handleKeyDown = (event: KeyboardEvent) => {
  if (event.key === "Escape") {
    routeToCreditnotesList();
  } else return;
};

const getCreditNote = async () => {
  creditNoteId.value = route.params.id as string;
  await creditNoteViewStore.getCreditNoteById(creditNoteId.value);
};

onMounted(async () => {
  document.addEventListener("keydown", handleKeyDown);
  await getCreditNote();
});
</script>
<style scoped lang="scss">
.c-credit-note {
  margin: var(--default-content-margin);
}
:deep(.p-inputtext) {
  &:enabled:focus {
    border: var(--floating-input-border-on-focus);
    box-shadow: none;
  }
}
</style>
