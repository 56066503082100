<template>
  <DataTable
    ref="tableRef"
    :value="previoslyCreditedInvoices"
    class="c-datatable"
    data-testId="previosly-credited-invoices-list"
    :stripedRows="true"
    :loading="loading"
    selectionMode="single"
    @row-dblclick="onRowDblClicked"
    @row-select="onRowSelected"
  >
    <ColumnGroup type="header">
      <Row>
        <template v-for="column in columns" :key="column.field">
          <Column :field="column.field" :style="column.style" />
        </template>
        <Column
          headerClass="text-center"
          :header="t('creditnote.headers.prev-credited')"
          class="w-28 pb-0"
          :colspan="2"
          style="background-color: var(--list-row-bg); border-radius: 12px 12px 0 0; text-align: center"
        />
      </Row>
      <Row>
        <template v-for="column in columns" :key="column.field">
          <Column :field="column.field" :header="column.header" :style="column.style" />
        </template>
        <Column
          field="previouslyCreditedQuantity"
          :header="t('creditnote.headers.quantity')"
          class="c-combined-columns"
          style="background-color: var(--list-row-bg)"
        />
        <Column
          field="previouslyCreditedSum"
          :header="t('creditnote.headers.sum')"
          class="c-combined-columns"
          style="background-color: var(--list-row-bg)"
        />
      </Row>
    </ColumnGroup>
    <Column field="invoiceNumber" class="text-right w-40" :header="t('creditnote.headers.invoiceNumber')" />
    <Column field="productNumber" class="w-40" :header="t('creditnote.headers.productNumber')">
      <template #body="{ data, field }">
        {{ data[field as keyof typeof data] || t("creditnote.freight") }}
      </template>
    </Column>
    <Column field="name" :header="t('creditnote.headers.productName')" />

    <Column field="quantity" class="text-right w-12" />

    <Column field="listPrice" class="text-right w-32">
      <template #body="{ data, field }">
        {{ data[field as keyof typeof data] !== null ? n(data[field as keyof typeof data], "decimal") : "" }}
      </template>
    </Column>
    <Column field="invoicedSum" class="text-right w-40">
      <template #body="{ data, field }">
        {{ n(data[field as keyof typeof data], "decimal") }}
      </template>
    </Column>

    <Column field="quantityToCredit" class="w-20 p-0">
      <template #body="{ data, field, index }">
        <div
          v-tooltip.bottom="isMaximumCreditedReached(data) ? { value: t('creditnote.maximum-amount-credited') } : ''"
        >
          <InputNumber
            v-if="editingRowIndex === index && data[field as keyof typeof data] !== null"
            v-model="data[field as keyof typeof data]"
            :inputId="`quantity-to-credit-${index}`"
            :min="0"
            :max="data.quantity - (findExistingCredit(data.invoiceId, data.positionNumber)?.quantity ?? 0)"
            :step="1"
            :locale="locale"
            mode="decimal"
            :data-testid="`quantity-to-credit-${index}`"
            inputClass="w-24 text-right"
            :disabled="isMaximumCreditedReached(data)"
            @update:modelValue="updatePriceAndSum(data, $event, data.listPriceToCredit)"
            @keydown.enter.prevent="onAddNewCreditableRow(data)"
          />
        </div>
      </template>
    </Column>
    <Column field="listPriceToCredit" class="w-32 p-0">
      <template #body="{ data, field, index }">
        <div
          v-tooltip.bottom="isMaximumCreditedReached(data) ? { value: t('creditnote.maximum-amount-credited') } : ''"
        >
          <InputNumber
            v-show="editingRowIndex === index"
            v-model="data[field as keyof typeof data]"
            :inputId="`price-to-credit-${index}`"
            :min="0"
            :max="(data.invoicedSum - data.previouslyCreditedSum) / (data.quantityToCredit || 1)"
            :step="0.01"
            :min-fraction-digits="2"
            :max-fraction-digits="2"
            :locale="locale"
            mode="decimal"
            :data-testid="`price-to-credit-${index}`"
            inputClass="w-32 text-right"
            :disabled="isMaximumCreditedReached(data)"
            @update:modelValue="updatePriceAndSum(data, data.quantityToCredit, $event)"
            @keydown.enter.prevent="onAddNewCreditableRow(data)"
          />
        </div>
      </template>
    </Column>

    <Column field="sumToCredit" class="w-32 text-right">
      <template #body="{ data, field, index }">
        <div :data-testid="`sum-to-credit-${index}`">
          {{ data[field as keyof typeof data] !== null ? n(data[field as keyof typeof data], "decimal") : "" }}
        </div>
      </template>
    </Column>
    <Column field="previouslyCreditedQuantity" class="w-24 text-right">
      <template #body="{ data, field }">
        {{ n(data[field as keyof typeof data], "decimal") }}
      </template>
    </Column>
    <Column field="previouslyCreditedSum" class="w-32 text-right">
      <template #body="{ data, field }">
        {{ n(data[field as keyof typeof data], "decimal") }}
      </template>
    </Column>

    <Column class="text-center p-0 w-12">
      <template #body="{ data, index }">
        <div
          v-tooltip.bottom="isMaximumCreditedReached(data) ? { value: t('creditnote.maximum-amount-credited') } : ''"
        >
          <div class="c-product-search-edit">
            <div class="flex justify-center items-center">
              <Button
                v-if="editingRowIndex === index"
                text
                type="button"
                :data-testid="`invoice-search-add-${index}`"
                class="p-0"
                :disabled="isMaximumCreditedReached(data)"
                @click="onAddNewCreditableRow(data)"
              >
                <span class="c-row-add material-symbols-outlined material-filled"> add_circle </span>
              </Button>
            </div>
          </div>

          <div v-if="editingRowIndex !== index && isLineAdded(data)">
            <span class="c-product-search-used material-symbols-outlined material-filled"> check_circle </span>
          </div>
        </div>
      </template>
    </Column>

    <template #empty> {{ t("creditnote.no-creditable-rows") }} </template>
  </DataTable>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { CreditableRow } from "@/models/credit-note/CreditableRow";
import { useInvoiceStore } from "@/stores/InvoiceStore";
import ColumnGroup from "primevue/columngroup";
import Row from "primevue/row";
import { ref } from "vue";
import type { DataTableRowDoubleClickEvent, DataTableRowSelectEvent } from "primevue/datatable";
import { nextTick } from "vue";

const { t, n, locale } = useI18n();
const invoiceStore = useInvoiceStore();
const editingRowIndex = ref<number | null>(null);
const tableRef = ref();

const columns = [
  { field: "invoiceNumber", header: t("creditnote.headers.invoiceNumber") },
  {
    field: "productNumber",
    header: t("creditnote.headers.productNumber"),
  },
  { field: "name", header: t("creditnote.headers.productName"), style: { "min-width": "150px" } },
  { field: "quantity", header: t("creditnote.headers.quantity") },
  { field: "listPrice", header: t("creditnote.headers.listPrice") },
  { field: "invoicedSum", header: t("creditnote.headers.invoicedSum") },
  { field: "quantityToCredit", header: t("creditnote.headers.quantityToCredit") },
  { field: "listPriceToCredit", header: t("creditnote.headers.listPriceToCredit") },
  { field: "sumToCredit", header: t("creditnote.headers.sumToCredit") },
];

const props = defineProps<{
  creditableRows: CreditableRow[];
  previoslyCreditedInvoices: CreditableRow[];
  loading?: boolean;
}>();

const isLineAdded = (row: CreditableRow) =>
  props.creditableRows.some((x) => x.invoiceId === row.invoiceId && x.positionNumber === row.positionNumber);

const emit = defineEmits<{ (e: "creditableRowAdded", value: CreditableRow): void }>();

const isMaximumCreditedReached = (row: CreditableRow) =>
  row.previouslyCreditedQuantity === row.quantity || row.previouslyCreditedSum === row.invoicedSum;

const findExistingCredit = (invoiceId: string, positionNumber: number) => {
  const existingCreditLines = invoiceStore.creditTotals.find((x) => x.id === invoiceId)?.creditedInvoiceLines;
  const existing = existingCreditLines?.find((x) => x.positionNumber === positionNumber);
  return existing;
};

const updatePriceAndSum = (row: CreditableRow, quantity: number | null, listPrice: number | null) => {
  row.sumToCredit = (quantity || 1) * (listPrice ?? 0);
  const max = row.invoicedSum - row.previouslyCreditedSum;
  if (row.sumToCredit > max) row.sumToCredit = max;
  if ((row.quantityToCredit || 1) * (row.listPriceToCredit ?? 0) > max) {
    row.listPriceToCredit = max / (row.quantityToCredit || 1);
  }
};

const onRowSelected = (event: DataTableRowSelectEvent) => {
  editingRowIndex.value = null;

  if (event.originalEvent instanceof KeyboardEvent && event.originalEvent.key === "Enter") {
    editingRowIndex.value = event.index;
    nextTick(() => {
      if (event.data.quantityToCredit !== null) {
        focusQuantityToCredit(event.index);
      } else {
        focusListPriceToCredit(event.index);
      }
    });
  }
};

const onRowDblClicked = (event: DataTableRowDoubleClickEvent) => {
  editingRowIndex.value = event.index;
  nextTick(() => {
    if (event.data.quantityToCredit !== null) {
      focusQuantityToCredit(event.index);
    } else {
      focusListPriceToCredit(event.index);
    }
  });
};

const focusQuantityToCredit = (index: number) => {
  editingRowIndex.value = index;
  const el = document.getElementById(`quantity-to-credit-${index}`);
  if (el) {
    el.focus();
  }
};

const focusListPriceToCredit = (index: number) => {
  editingRowIndex.value = index;
  const el = document.getElementById(`price-to-credit-${index}`);
  if (el) {
    el.focus();
  }
};

const onAddNewCreditableRow = (row: CreditableRow) => {
  editingRowIndex.value = null;
  if (isLineAdded(row)) return;
  emit("creditableRowAdded", row);
};
</script>
