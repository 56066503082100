import { creditNoteApi } from "../api/credit-note/CreditNoteApi";
import { useErrorHandler } from "@/api/ErrorHandler";
import { useAuth } from "@cumulus/event-bus";
import { CreditNote } from "../models/credit-note/CreditNote";
import { ref } from "vue";
import { defineStore } from "pinia";
import cloneDeep from "lodash.clonedeep";

export const useCreditNoteViewStore = defineStore("credit-note-view-store", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const initialCreditNote = ref<CreditNote>(new CreditNote());
  const currentCreditNote = ref<CreditNote>(new CreditNote());
  const loading = ref(false);

  const getCreditNoteById = async (id: string) => {
    loading.value = true;
    try {
      const authHeaders = await getAuthHeaders();
      const creditNoteResult = await creditNoteApi.get(authHeaders, id);
      if (!creditNoteResult) {
        return null;
      }
      currentCreditNote.value = creditNoteResult;
      initialCreditNote.value = cloneDeep(creditNoteResult);
      return currentCreditNote.value;
    } catch (error) {
      await handleError(error);
      throw error;
    } finally {
      loading.value = false;
    }
  };

  return {
    initialCreditNote,
    currentCreditNote,
    loading,
    getCreditNoteById,
  };
});
