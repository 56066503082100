import { Available } from "./Available";

export class PaymentTerm {
  id = "";
  companyId = "";
  clientId = "";
  name = "";
  shortName = "";
  description = "";
  creditDays = 0;
  available = new Available();
}
