import { httpClient } from "@cumulus/http";
import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/api/ErrorHandler";
import { Warehouse } from "@/models/credit-note/Warehouse";

const uri =
  typeof import.meta.env.VITE_APP_API_WAREHOUSE !== "undefined" && import.meta.env.VITE_APP_API_WAREHOUSE
    ? import.meta.env.VITE_APP_API_WAREHOUSE
    : `${import.meta.env.VITE_APP_API_URL as string}`;

export const userWarehouseApi = () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const errorHandler = async <T>(f: () => Promise<T>): Promise<T> => {
    try {
      return await f();
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    async getAllWarehouses(): Promise<Warehouse[]> {
      return errorHandler(async () =>
        httpClient(uri, await getAuthHeaders())
          .get("/warehouses")
          .then(({ data }) => data),
      );
    },
  };
};
