<template>
  <label for="payment-term-credit-days">
    {{ t(`payment-term.dialog.credit-days.label`) }}
  </label>
  <InputNumber
    id="payment-term-credit-days"
    v-model="creditDaysComputed"
    :min="0"
    data-testid="payment-term-credit-days"
    class="inputfield w-full"
    input-class="w-full"
    :placeholder="t(`payment-term.dialog.credit-days.placeholder`)"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  creditDays: number;
}>();

const emit = defineEmits<{
  (e: "update:creditDays", value: number): void;
}>();

const creditDaysComputed = computed<number>({
  get: () => {
    return props.creditDays ?? 0;
  },
  set: (value) => {
    emit("update:creditDays", value);
  },
});
</script>
