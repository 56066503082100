<template>
  <label for="payment-term-name">
    {{ t(`payment-term.dialog.name.label`) }}
  </label>
  <InputText
    id="payment-term-name"
    v-model="name"
    data-testid="payment-term-name"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.name.$error }"
    :placeholder="t(`payment-term.dialog.name.placeholder`)"
  />
  <small v-if="val.name.$error" id="payment-term-name-help" data-testid="payment-term-name-error" class="p-error">{{
    val.name.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import { useVuelidate } from "@vuelidate/core";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const name = defineModel<string>("name", {
  required: true,
});

const rules = {
  name: {
    required: required,
  },
};

const val = useVuelidate(rules, { name: name });
</script>
