<template>
  <label for="payment-term-description">
    {{ t(`payment-term.dialog.description.label`) }}
  </label>
  <InputText
    id="payment-term-description"
    v-model="descriptionComputed"
    data-testid="payment-term-description"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.description.$error }"
    :placeholder="t(`payment-term.dialog.description.placeholder`)"
  />
  <small
    id="payment-term-description-help"
    data-testid="payment-term-description-error"
    class="p-error"
    v-if="val.description.$error"
    >{{ val.description.$errors[0].$message }}</small
  >
</template>

<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  description: string;
}>();

const emit = defineEmits<{
  (e: "update:description", value: string): void;
}>();

const descriptionComputed = computed<string>({
  get: () => {
    return props.description ?? "";
  },
  set: (value) => {
    emit("update:description", value);
  },
});

const rules = {
  description: {
    required: helpers.withMessage(
      t("common.field-required", { fieldName: t("payment-term.dialog.description.label") }),
      required
    ),
  },
};

const val = useVuelidate(rules, props);
</script>
