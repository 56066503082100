<template>
  <label class="mr-2"
    ><label>{{ t("currency.active") }}</label></label
  >
  <Checkbox v-model="selectedCurrencyStateComputed" :binary="true" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { CurrencyState } from "@/models/currency/CurrencyState";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  currencyState: CurrencyState;
}>();

const emit = defineEmits<{
  (e: "update:currencyState", value: CurrencyState): void;
}>();

const { t } = useI18n();

const selectedCurrencyStateComputed = computed<boolean>({
  get: () => {
    return props.currencyState === CurrencyState.Active ? true : false;
  },
  set: (value) => {
    emit("update:currencyState", value ? CurrencyState.Active : CurrencyState.Inactive);
  },
});
</script>
