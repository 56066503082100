<template>
  <label for="account-settings-description">{{ t("account-settings.description") }}</label>
  <InputText
    id="account-settings-description"
    v-model="description"
    :placeholder="t('placeholder.type', { property: t('account-settings.description').toLowerCase() })"
    class="inputfield w-full"
    data-testid="c-account-settings-description"
  />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const description = defineModel<string>("description", {
  required: true,
});

const { t } = useI18n();
</script>
