import { CustomerSearchFilters } from "./CustomerSearchFilters";

export class CustomerSearchRequest {
  query = "";
  page = 1;
  pageSize = 25;
  sortBy = "";
  sortOrder = "asc";
  filters = new CustomerSearchFilters();

  constructor(query: string) {
    this.query = query;
  }
}
