<template>
  <ContextMenu ref="cm" :model="menuModel" data-testid="credit-note-context-menu" />
  <div class="card">
    <ColumnOptionsMenu :label="t('creditnote.header')" :itemsList="items"></ColumnOptionsMenu>

    <DataTable
      :value="creditNotes"
      dataKey="id"
      data-testid="credit-note-search-result"
      :autoLayout="true"
      responsiveLayout="stack"
      breakpoint="999px"
      class="c-invoice-table c-datatable"
      :loading="loading"
      :sortField="sortField"
      :sortOrder="sortOrder"
      :paginator="true"
      :rows="pageSize"
      :rowsPerPageOptions="[50, 100]"
      :currentPageReportTemplate="
        t('common.current-page-template', { first: '{first}', last: '{last}', totalRecords: '{totalRecords}' })
      "
      paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
      stripedRows
      scrollable
      scrollHeight="75vh"
      :metaKeySelection="false"
      @row-dblclick="onRowDblClick"
      contextMenu
      v-model:contextMenuSelection="selectedCreditNote"
      @rowContextmenu="onRowContextMenu"
      removableSort
      @sort="onSort"
      @page="onPage"
      :resizableColumns="true"
      columnResizeMode="fit"
      @column-resize-end="onColumnResizeEnd"
      :reorderable-columns="true"
      @column-reorder="onColumnReorder"
      :key="renderKey as unknown as number"
    >
      <Column
        v-for="(col, index) of selectedColumnsComputed as unknown as DataTableColumn[]"
        :field="col.field"
        :header="col.header"
        :key="col.field + '_' + index"
        :class="col.class"
        :sortable="col.sortable"
        :pt="{
          headerCell: {
            id: col.field,
          },
        }"
        :style="col.size ? `width: ${col.size}px; max-width: ${col.size}px;` : ''"
      >
        <template #body="{ data, field, index }">
          <template v-if="col.field === CreditNotesLinesColumns.CreditNoteDate">
            <span>{{ d(data.creditNoteDate, "long") }}</span>
          </template>
          <template v-else-if="col.field === CreditNotesLinesColumns.CreditNoteNumber">
            <span>{{ data.creditNoteNumber }}</span>
          </template>
          <template v-else-if="col.field === CreditNotesLinesColumns.CreditNoteLines">
            {{ [...new Set(data.creditNoteLines.map((x: any) => x.invoiceNumber)).values()].join(", ") }}</template
          >
          <template v-else-if="col.field === CreditNotesLinesColumns.OrderedByCustomerNumber">
            <span>{{ data.orderedBy.customerNumber }}</span>
          </template>
          <template v-else-if="col.field === CreditNotesLinesColumns.OrderedByCustomerName">
            <span>{{ data.orderedBy.customerName }}</span>
          </template>
          <template v-else-if="col.field === CreditNotesLinesColumns.TotalSum">
            <span>{{ n(-data.totalSum, "decimal") }}</span>
          </template>
          <template v-else-if="col.field === CreditNotesLinesColumns.CreditComment">
            <span>{{ data.creditComment }}</span>
          </template>
          <template v-else-if="col.field === CreditNotesLinesColumns.Pdf">
            <img :data-testid="'btn-show-pdf-' + index" :src="pdfIcon" @click="downloadPdf(data.id)" />
          </template>
          <template v-else>
            {{ data[field] }}
          </template>
        </template>
      </Column>
      <template #loading>
        <span v-if="loading">{{ t("common.loading") }}</span>
      </template>
      <template #empty>
        <span>{{ t("invoice.no-result") }}</span>
      </template>
    </DataTable>
  </div>
  <ColumnChooser
    v-model:visibleDialog="visible"
    v-model:selectedColumns="selectedColumnsComputed"
    :columns="filteredColumns"
    :label="t('common.reset')"
    :onSelectAllChange="onSelectAllChange"
    :selectAll="selectAll"
    @resetColumns="resetColumns"
    @columnSelected="onColumnSelected"
    @columnUnselected="onColumnUnselected"
  />
  <CreditNotePrintDialog
    v-model:visibleDialog="showPrintModal"
    :creditNote="selectedCreditNote"
    v-if="showPrintModal"
  />
</template>
<script setup lang="ts">
import { storeToRefs } from "pinia";
import { onMounted, ref, nextTick, computed } from "vue";
import { useI18n } from "vue-i18n";
import pdfIcon from "@/assets/PDF_file_icon.svg";
import { CreditNotesLinesColumns } from "@/models/search/credit-notes/CreditNotesLinesColumns";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import { useDocument } from "@/api/document/DocumentService";
import {
  DataTableRowClickEvent,
  DataTableRowDoubleClickEvent,
  DataTablePageEvent,
  DataTableSortEvent,
  DataTableSelectAllChangeEvent,
} from "primevue/datatable";
import CreditNotePrintDialog from "@/components/CreditNotePrintDialog.vue";
import { CreditNote } from "@/models/credit-note/CreditNote";
import { useCreditNoteListStore } from "@/stores/CreditNoteListStore";
import { useAuth } from "@cumulus/event-bus";
import router from "@/router/router";
import { NIL as emptyUuid } from "uuid";
import { ColumnChooser, useTablePreferences, ColumnOptionsMenu } from "@cumulus/components";
import type { DataTableColumn } from "@cumulus/components";

const { getUser } = useAuth();

const { t, d, n } = useI18n();
const creditNoteListStore = useCreditNoteListStore();
const { creditNotes, loading } = storeToRefs(creditNoteListStore);
const pageSize = ref<number>(50);
const sortField = ref("");
const sortOrder = ref(-1);
const credits = ref<CreditNote[]>([]);
const searchCreditNoteList = ref();
const visible = ref(false);

const emit = defineEmits<{
  (e: "update:page", value: number): void;
  (e: "update:pageSize", value: number): void;
  (e: "update:sortOrder", value: number): void;
  (e: "update:sortField", value: string): void;
}>();

const creditNotesSearchColumns: DataTableColumn[] = [
  { field: "creditNoteDate", header: t("creditnote.list-headers.date-created"), sortable: true },
  {
    field: "creditNoteNumber",
    header: t("creditnote.list-headers.creditnote-number"),
    class: "text-right",
    sortable: true,
  },
  {
    field: "creditNoteLines",
    header: t("creditnote.list-headers.invoice-numbers"),
    class: "text-right",
    sortable: true,
  },
  {
    field: "orderedBy.customerNumber",
    header: t("creditnote.list-headers.customer-number"),
    class: "text-right",
    sortable: true,
  },
  {
    field: "orderedBy.customerName",
    header: t("creditnote.list-headers.customer-name"),
    sortable: true,
  },
  { field: "totalSum", header: t("creditnote.list-headers.amount"), class: "text-right", sortable: true },
  { field: "creditComment", header: t("creditnote.list-headers.comment"), sortable: true },
  { field: "pdf", header: t("invoice.pdf"), class: "c-pdf-icon", sortable: false },
];

const {
  selectedColumnsComputed,
  renderKey,
  onColumnReorder,
  resetColumns,
  orderedColumns,
  onColumnResizeEnd,
  onColumnSelected,
  onColumnUnselected,
} = useTablePreferences("creditNotesSearch", creditNotesSearchColumns, null, (await getUser()).getEmployee().id);

const filteredColumns = computed(() => {
  return orderedColumns.value.filter(
    (col) =>
      col.field !== CreditNotesLinesColumns.CreditNoteLines &&
      col.field !== CreditNotesLinesColumns.OrderedByCustomerNumber &&
      col.field !== CreditNotesLinesColumns.TotalSum
  );
});

const items = [
  {
    label: t("common.reload"),
    icon: "pi pi-refresh c-default-button c-circular-icon",
    command: () => {
      onClickCreditNoteRefresh();
    },
  },
  {
    label: t("common.column-chooser"),
    icon: "pi pi-list c-default-button c-circular-icon",

    class: "c-column-chooser",
    command: () => {
      visible.value = true;
    },
  },
];

const selectAll = ref(false);
const onSelectAllChange = (event: DataTableSelectAllChangeEvent) => {
  selectAll.value = event.checked;
  selectedColumnsComputed.value = event.checked
    ? creditNotesSearchColumns
    : creditNotesSearchColumns.filter(
        (c) =>
          c.field === CreditNotesLinesColumns.CreditNoteLines ||
          c.field === CreditNotesLinesColumns.OrderedByCustomerNumber ||
          c.field === CreditNotesLinesColumns.TotalSum
      );
};

const onClickCreditNoteRefresh = async () => {
  loading.value = true;
  await creditNoteListStore.getCreditNotes();
};

onMounted(async () => {
  await creditNoteListStore.getCreditNotes();
});

const menuModel = ref([{ label: t("common.print"), icon: "pi pi-fw pi-print", command: () => togglePrintModal() }]);
const togglePrintModal = () => {
  showPrintModal.value = !showPrintModal.value;
};

const selectedCreditNote = ref();
const showPrintModal = ref(false);

const previouslyFocusRow = ref();
const cm = ref();
const onRowContextMenu = (event: DataTableRowClickEvent) => {
  cm.value.show(event.originalEvent);
};

const toast = useCumulusToast(useToast());
const { getCreditNoteUrl } = useDocument();
const downloadPdf = async (creditNoteUrl: string) => {
  try {
    const url = await getCreditNoteUrl(creditNoteUrl);

    if (url && url.length > 0) {
      window.open(url, "_blank");
    } else {
      toast.add({
        severity: "error",
        summary: t("common.an-error-occured"),
        detail: t("creditnote.download-link-missing"),
        closable: true,
      });
    }
  } catch {
    // ignored
  }
};

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  openCreditNote(event.data.id);
};

const openCreditNote = (creditnoteId: string) => {
  creditnoteId != null && creditnoteId !== emptyUuid
    ? router.push({ name: "credit-note-view", params: { id: creditnoteId } })
    : toast.add({
        severity: "warn",
        summary: t("creditnote.add.toast.not-found.summary"),
        detail: t("creditnote.add.toast.not-found.detail"),
        closable: true,
      });
};

const onSort = async (event: DataTableSortEvent) => {
  let sortField = "";
  nextTick(() => {
    if (typeof event.sortField === "string") {
      sortField = event.sortField;
    }
    focusSearchResult();

    emit("update:sortOrder", -sortOrder.value);
    emit("update:sortField", sortField);
    emit("update:page", 1);
  });
};

const onPage = async (event: DataTablePageEvent) => {
  nextTick(() => {
    focusSearchResult();
    emit("update:page", event.page + 1);
    emit("update:pageSize", event.rows);
  });
};

const focusSearchResult = () => {
  if (credits.value.length > 0 && searchCreditNoteList.value) {
    if (previouslyFocusRow.value) {
      nextTick(() => {
        previouslyFocusRow.value.focus();
      });
    } else {
      nextTick(() => {
        searchCreditNoteList.value.$el.querySelector("tbody tr:first-of-type").focus();
      });
    }
  }
};
</script>
<style scoped lang="scss">
:deep(.p-paginator) {
  .p-paginator-first {
    margin-left: auto;
  }
  .p-paginator-current {
    margin-left: auto;
  }
}

.c-pdf-icon img {
  display: inline-block;
  width: 1.68rem;
  cursor: pointer;
}

.c-creditnotes {
  margin: var(--default-content-margin);
}
</style>
