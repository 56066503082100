<template>
  <label for="currency-select-country">{{ t("currency.country") }}</label>
  <Select
    id="currency-select-country"
    v-model="countryIsoComputed"
    :options="props.countries"
    optionLabel="name"
    optionValue="iso"
    class="w-full"
    data-testid="currency-select-country"
    pt:list:data-testid="currency-select-country-list"
  />
  <small data-testid="country-iso-error" class="p-error" v-if="val.countryIso.$error">{{
    val.countryIso.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import { Country } from "@/models/country/Country";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  countryIso: string;
  countries: Country[];
}>();

const emit = defineEmits<{
  (e: "update:countryIso", value: string): void;
}>();

const countryIsoComputed = computed<string>({
  get: () => props.countryIso,
  set: (value: string) => emit("update:countryIso", value),
});

const { t } = useI18n();

const rules = {
  countryIso: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
