import { AccountPlan } from "@/models/account-plan/AccountPlan";
import { defineStore } from "pinia";
import { ref } from "vue";
import { accountPlanApi } from "../api/account-plan/AccountPlanApi";
import { useAuth } from "@cumulus/event-bus";
import { NewAccountPlan } from "@/models/account-plan/NewAccountPlan";
import { useErrorHandler } from "@/api/ErrorHandler";

export const useAccountPlanStore = defineStore("account-plan-store", () => {
  const accountPlans = ref<AccountPlan[]>([]);

  const { getAuthHeaders } = useAuth();
  const { handleError } = useErrorHandler();

  const getAccountPlans = async () => {
    try {
      const authHeaders = await getAuthHeaders();
      accountPlans.value = await accountPlanApi.getAll(authHeaders);
      accountPlans.value.sort((a, b) => a.accountNumber.localeCompare(b.accountNumber));
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const addAccountPlan = async (newAccountPlan: NewAccountPlan): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await accountPlanApi.create(authHeaders, newAccountPlan);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const editAccountPlan = async (accountPlan: AccountPlan): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await accountPlanApi.update(authHeaders, accountPlan);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    accountPlans,
    getAccountPlans,
    addAccountPlan,
    editAccountPlan,
  };
});
