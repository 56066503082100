export class AccountSettingProduct {
  accountGroupId = "";
  inventoryAccountPlanId = "";
  salesAccountPlanId = "";
  salesTaxFreeAccountPlanId = "";
  purchaseAccountPlanId = "";
  purchaseTaxFreeAccountPlanId = "";
  costGoodsSoldAccountPlanId = "";
  isDefault = false;

  constructor(productSetting?: AccountSettingProduct) {
    if (!productSetting) return;
    this.inventoryAccountPlanId = productSetting.inventoryAccountPlanId;
    this.salesAccountPlanId = productSetting.salesAccountPlanId;
    this.salesTaxFreeAccountPlanId = productSetting.salesTaxFreeAccountPlanId;
    this.purchaseAccountPlanId = productSetting.purchaseAccountPlanId;
    this.purchaseTaxFreeAccountPlanId = productSetting.purchaseTaxFreeAccountPlanId;
    this.costGoodsSoldAccountPlanId = productSetting.costGoodsSoldAccountPlanId;
  }
}
