<template>
  <FloatLabel variant="on">
    <InputText
      id="contact-email"
      data-testid="creditnote-contact-email"
      v-model="email"
      class="w-full"
      :fluid="true"
      :disabled="disabled"
      :maxlength="500"
      aria-describedby="contact-email-help"
      autofocus
      v-tooltip.bottom="{
        value: t('placeholder.type', { property: t('creditnote.contact.email').toLowerCase() }),
        showDelay: 1000,
        hideDelay: 300,
      }"
    />
    <label for="contact-email">
      {{ t(`creditnote.contact.email`) }}
    </label>
  </FloatLabel>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const email = defineModel<string>("email", {
  required: true,
});

defineProps<{
  disabled?: boolean;
}>();
</script>
