import { InvoiceSearchFilters } from "./InvoiceSearchFilters";

export class InvoiceSearchRequest {
  query = "";
  page = 1;
  pageSize = 25;
  filters: InvoiceSearchFilters = new InvoiceSearchFilters();
  sortBy = "";
  sortOrder = "asc";

  constructor(query: string, filters: InvoiceSearchFilters) {
    this.query = query;
    this.filters = filters;
  }
}
