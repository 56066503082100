<template>
  <Popover
    id="contact_overlay_panel"
    ref="contactPanelRef"
    appendTo="body"
    :dismissable="true"
    :breakpoints="{ '960px': '75vw', '640px': '100vw', '90000px': '800px' }"
    class="c-contact-panel"
    @keydown.esc.stop="hideOverlayPanel"
  >
    <CustomerContactList :contact="contact" :customerContacts="customerContacts" @selectedContact="onContactSelected" />
  </Popover>
</template>

<script setup lang="ts">
import type { CustomerContact } from "@/models/customer/CustomerContact";
import CustomerContactList from "./CustomerContactList.vue";
import { ref } from "vue";
import { CreditNoteContact } from "@/models/credit-note/CreditNoteContact";

const contactPanelRef = ref();

defineProps<{
  contact: CreditNoteContact;
  customerContacts: CustomerContact[];
}>();

const emit = defineEmits<{ (e: "contactSelected", value: CustomerContact): void }>();

const toggle = (event: Event) => contactPanelRef.value.toggle(event);
const show = (event: Event) => contactPanelRef.value.show(event);
const hideOverlayPanel = () => contactPanelRef.value.hide();

defineExpose({ toggle: toggle, show: show });

const onContactSelected = (contact: CustomerContact) => {
  contactPanelRef.value.hide();
  emit("contactSelected", contact);
};
</script>

<style scoped lang="scss">
.c-contact-panel .p-overlaypanel-content {
  height: auto;
}
</style>
