import { InvoiceSearchRequest } from "@/models/search/invoice/InvoiceSearchRequest";
import { searchApi } from "./SearchApi";
import { InvoiceSearchResponse } from "@/models/search/invoice/InvoiceSearchResponse";
import { useErrorHandler } from "../ErrorHandler";
import { useAuth } from "@cumulus/event-bus";

export const useInvoiceSearchService = () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const invoiceSearch = async (request: InvoiceSearchRequest): Promise<InvoiceSearchResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await searchApi.invoiceSearch(authHeaders, request);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { invoiceSearch };
};
