import { CreditableRow } from "./CreditableRow";
import { NIL as emptyUuid } from "uuid";

export class CreditNoteLine {
  positionNumber = 0;
  invoiceId = "";
  orderId = "";
  freightMethodId = "";
  freightMethodName = "";
  productId = "";
  productName = "";
  productNumber = "";
  gtin = "";
  noReference = "";
  quantity = 0;
  costPriceInvoice = 0;
  price = 0;
  priceIncVat = 0;
  vatAmount = 0;
  sumLine = 0;
  sumLineIncVat = 0;
  sumLineVatAmount = 0;
  vatPercentage = 0;
  invoiceNumber: number | null = null;
  invoicedCost = 0;
  isNoReferenceLine = false;

  static mapFromCreditRows(creditRows: CreditableRow[]) {
    return creditRows
      .filter((c) => c.included)
      .map((c) => {
        const sumLine = c.sumToCredit;
        const vat = (sumLine * c.vatPercentage) / 100;
        const listPrice = c.listPriceToCredit ?? 0;
        const vatAmount = (listPrice * c.vatPercentage) / 100;
        return {
          positionNumber: c.positionNumber,
          invoiceId: c.invoiceId ? c.invoiceId : emptyUuid,
          orderId: c.orderId ? c.orderId : emptyUuid,
          freightMethodId: c.freightMethodId ? c.freightMethodId : emptyUuid,
          freightMethodName: c.freightMethodId ? c.name : "",
          productId: c.productId ? c.productId : emptyUuid,
          productName: c.productId ? c.name : "",
          productNumber: c.productNumber,
          gtin: c.gtin,
          noReference: c.isNoReferenceLine ? c.name : "",
          quantity: c.quantityToCredit ?? 0,
          costPriceInvoice: 0,
          price: listPrice,
          priceIncVat: listPrice + vatAmount,
          vatAmount: vatAmount,
          sumLine: c.sumToCredit,
          sumLineIncVat: sumLine + vat,
          sumLineVatAmount: vat,
          vatPercentage: c.vatPercentage,
          invoiceNumber: c.invoiceNumber,
          invoicedCost: c.invoicedCost,
          isNoReferenceLine: c.isNoReferenceLine,
        };
      });
  }
}
