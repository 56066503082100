<template>
  <FloatLabelTextArea
    v-model:value="comment"
    :disabled="disabled"
    :placeholder="t('placeholder.type', { property: t('creditnote.comment').toLowerCase() })"
    :label="t('creditnote.comment')"
    data-testid="creditnote-comment"
  />
</template>
<script setup lang="ts">
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const comment = defineModel<string>("comment", {
  required: true,
});

defineProps<{
  disabled?: boolean;
}>();
</script>
