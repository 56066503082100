<template>
  <label for="tax-rate">
    {{ t(`tax.dialog.rate.label`) }}
  </label>
  <InputNumber
    id="tax-rate"
    v-model="rateComputed"
    :min="0"
    :allow-empty="false"
    suffix="%"
    data-testid="tax-rate"
    class="inputfield w-full"
    :placeholder="t(`tax.dialog.rate.placeholder`)"
  />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  rate: number;
}>();

const emit = defineEmits<{
  (e: "update:rate", value: number): void;
}>();

const rateComputed = computed<number>({
  get: () => {
    return props.rate ?? 0;
  },
  set: (value) => {
    emit("update:rate", value);
  },
});
</script>
