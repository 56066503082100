<template>
  <Select
    v-model="accountPlanId"
    :options="accountPlans"
    optionLabel="accountNumber"
    optionValue="id"
    :placeholder="t('placeholder.select', { property: t('account-plan.header').toLocaleLowerCase() })"
    class="w-full"
    :data-testid="props.dataTestId"
    :class="{ 'p-invalid': val && val.$error }"
    showClear
    :pt:list:data-testid="`${dataTestId}-list`"
    @change="onChange"
  >
    <template #clearicon>
      <i v-if="accountPlanId === emptyUuid" class=""></i>
    </template>
    <template #option="{ option }">
      <div class="flex items-center">
        <span class="mr-2">{{ option.accountNumber }}</span>
        <span>{{ option.accountName }}</span>
      </div>
    </template>
  </Select>
  <small v-if="val && val.$error" class="p-error" :data-testid="`${dataTestId}-error`">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script lang="ts" setup>
import { useAccountPlanStore } from "@/stores/AccountPlanStore";
import useVuelidate from "@vuelidate/core";
import { storeToRefs } from "pinia";
import { useI18n } from "vue-i18n";
import { required } from "@/locales/i18n-validators";
import { type DropdownChangeEvent } from "primevue/dropdown";
import { NIL as emptyUuid } from "uuid";
import { helpers } from "@vuelidate/validators";

const props = defineProps<{
  isDefault?: boolean;
  isRequired?: boolean;
  dataTestId?: string;
}>();

const accountPlanId = defineModel<string>("accountPlanId", {
  required: true,
});

const { accountPlans } = storeToRefs(useAccountPlanStore());
const { t } = useI18n();

const onChange = (event: DropdownChangeEvent) => {
  if (event.value === null) {
    accountPlanId.value = emptyUuid;
  }
};

const rules = {
  accountPlanId: {
    required,
    notZeroOrDisabled: helpers.withMessage(t("validations.required"), (value: string) => {
      return value !== emptyUuid;
    }),
  },
};

const val = props.isDefault || props.isRequired ? useVuelidate(rules, { accountPlanId }) : undefined;
</script>
