import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/api/ErrorHandler";
import { supplierApi } from "./SupplierApi";
import { NumberSeries } from "@/number-series/model/NumberSeries";
import { NumberSeriesType } from "@/number-series/model/NumberSeriesType";

export function useSupplierService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getSupplierNumberSeries = async (): Promise<NumberSeries> => {
    try {
      const authHeaders = await getAuthHeaders();
      const numberSeries = await supplierApi.getNumberSeries(authHeaders);

      if (numberSeries !== undefined) {
        numberSeries.type = NumberSeriesType.Supplier;
      }

      return numberSeries;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateSupplierNumberSeries = async (numberSeries: NumberSeries): Promise<void> => {
    if (numberSeries.type !== NumberSeriesType.Supplier) {
      throw new Error(`Invalid number series type. Expected Supplier, got ${numberSeries.type} instead.`);
    }

    try {
      const authHeaders = await getAuthHeaders();
      await supplierApi.updateNumberSeries(authHeaders, numberSeries);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getSupplierNumberSeries,
    updateSupplierNumberSeries,
  };
}
