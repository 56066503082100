import { NewCreditNote } from "./NewCreditNote";
import { ReturnProductInfo } from "./ReturnProductInfo";

export class CreditNoteRequest {
  public creditNote: NewCreditNote = new NewCreditNote();
  public returnProductsToStock = false;
  public returnProducts: ReturnProductInfo[] = [];

  public static createFromReturnProducts(
    creditNote: NewCreditNote,
    returnProducts?: ReturnProductInfo[]
  ): CreditNoteRequest {
    const request = new CreditNoteRequest();
    request.creditNote = creditNote;
    request.returnProducts = returnProducts ?? [];
    request.returnProductsToStock = returnProducts && returnProducts.length > 0 ? true : false;
    return request;
  }
}
