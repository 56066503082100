import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "../ErrorHandler";
import { currencyApi } from "./CurrencyApi";
import { CurrencyInfo } from "@/models/currency/CurrencyInfo";
import { Currency } from "@/models/currency/Currency";
import { CurrencyExchangeRate } from "@/models/currency/CurrencyExchangeRate";

export function useCurrencyService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAllCurrencies = async (): Promise<CurrencyInfo[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      const currencies = await currencyApi.getAll(authHeaders);
      return currencies;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const addCurrency = async (currency: Currency): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await currencyApi.create(authHeaders, currency);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateCurrency = async (currency: Currency): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await currencyApi.update(authHeaders, currency);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateCurrencyExchangeRates = async (currencyExchangeRates: CurrencyExchangeRate[]): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await currencyApi.updateExchangeRate(authHeaders, currencyExchangeRates);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getAllCurrencies,
    addCurrency,
    updateCurrency,
    updateCurrencyExchangeRates,
  };
}
