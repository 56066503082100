<template>
  <label>{{ t("currency.description") }}</label>
  <InputText v-model="descriptionComputed" class="w-full" data-testid="currency-description" />
</template>

<script setup lang="ts">
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  description: string;
}>();

const emit = defineEmits<{
  (e: "update:description", value: string): void;
}>();

const { t } = useI18n();

const descriptionComputed = computed<string>({
  get: () => props.description,
  set: (value: string) => emit("update:description", value),
});
</script>
