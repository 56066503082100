import { Customer } from "./Customer";
import { EconomyTransferLine } from "./EconomyTransferLine";
import { Supplier } from "./Supplier";
import { TransferDocumentType } from "./TransferDocumentType";

export class EconomyTransfer {
  id = "";
  transferNumber = 0;
  documentNumber = 0;
  purchaseOrderNumber = "";
  transferDocumentType: TransferDocumentType = TransferDocumentType.Invoice;
  attachmentTypeCode = 0;
  documentDate = "";
  paymentDueDate = "";
  currencyIso = "";
  currencyUnit = 0;
  exchangeRate = 0;
  customer: Customer = new Customer();
  supplier: Supplier = new Supplier();
  lines: EconomyTransferLine[] = [];
  isTransfered = false;
  createdDateTime = "";
}
