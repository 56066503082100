<template>
  <div class="col-span-12 xl:col-span-4">
    <Card>
      <template #content>
        <div class="h-full">
          <div class="grid grid-cols-12 gap-4">
            <div class="col-span-12 sm:col-span-6">
              <label for="c-economy-transfer-customer-name">
                {{ t("economy-transfer.supplier.name") }}
              </label>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <span id="c-economy-transfer-customer-name" data-testid="c-economy-transfer-customer-name">{{
                supplier.name
              }}</span>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <label for="c-economy-transfer-customer-number">
                {{ t("economy-transfer.supplier.number") }}
              </label>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <span id="c-economy-transfer-customer-number" data-testid="c-economy-transfer-customer-number">{{
                supplier.supplierNumber
              }}</span>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <label for="c-economy-transfer-customer-number">
                {{ t("economy-transfer.purchase-order-number") }}
              </label>
            </div>
            <div class="col-span-12 sm:col-span-6">
              <span id="c-economy-transfer-customer-number" data-testid="c-economy-transfer-customer-number">{{
                purchaseOrderNumber
              }}</span>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import { Supplier } from "@/models/economy-transfer/Supplier";
import { useI18n } from "vue-i18n";

defineProps<{
  supplier: Supplier;
  purchaseOrderNumber: string;
}>();

const { t } = useI18n();
</script>
