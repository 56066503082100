<template>
  <InputNumber
    v-model="vatCodeComputed"
    inputClass="inputfield w-full"
    :useGrouping="false"
    :data-testid="dataTestId"
  />
  <small class="p-error" v-if="val && val.$error" data-testid="c-account-settings-vat-codes-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script lang="ts" setup>
import { required } from "@/locales/i18n-validators";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";

const props = defineProps<{
  dataTestId?: string;
  isRequired?: boolean;
}>();

const vatCode = defineModel<string>("vatCode", {
  required: true,
});

const vatCodeComputed = computed<number>({
  get: () => {
    return parseInt(vatCode.value) || 0;
  },
  set: (value: number) => {
    vatCode.value = value.toString();
  },
});

const rules = {
  vatCode: {
    required,
  },
};

const val = props.isRequired ? useVuelidate(rules, { vatCode }) : null;
</script>
