import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/api/ErrorHandler";
import { customerApi } from "./CustomerApi";
import { NumberSeries } from "@/number-series/model/NumberSeries";
import { NumberSeriesType } from "@/number-series/model/NumberSeriesType";

export function useCustomerService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getCustomerNumberSeries = async (): Promise<NumberSeries> => {
    try {
      const authHeaders = await getAuthHeaders();
      const numberSeries = await customerApi.getNumberSeries(authHeaders);

      if (numberSeries !== undefined) {
        numberSeries.type = NumberSeriesType.Customer;
      }

      return numberSeries;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updateCustomerNumberSeries = async (numberSeries: NumberSeries): Promise<void> => {
    if (numberSeries.type !== NumberSeriesType.Customer) {
      throw new Error(`Invalid number series type. Expected Customer, got ${numberSeries.type} instead.`);
    }

    try {
      const authHeaders = await getAuthHeaders();
      await customerApi.updateNumberSeries(authHeaders, numberSeries);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getCustomerNumberSeries,
    updateCustomerNumberSeries,
  };
}
