<template>
  <label for="tax-country">
    {{ t(`tax.dialog.countryIso.label`) }}
  </label>
  <Select
    id="tax-country"
    :options="countryOptionsComputed"
    optionValue="iso"
    optionLabel="name"
    v-model="countryComputed"
    data-testid="tax-country"
    class="w-full"
    :class="{ 'p-invalid': val.country.$error }"
    :placeholder="
      t('tax.dialog.countryIso.placeholder', { property: t('tax.dialog.countryIso.placeholder').toLowerCase() })
    "
    @show="isActive = true"
    @hide="isActive = false"
    @keydown.esc="isActive === true ? $event.stopPropagation() : ''"
    pt:list:data-testid="tax-country-list"
  />
  <small data-testid="tax-country-error" class="p-error" v-if="val.country.$error">{{
    val.country.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { Country } from "@/models/country/Country";
import useVuelidate from "@vuelidate/core";
import { computed, ref } from "vue";
import { useI18n } from "vue-i18n";
import { required } from "@vuelidate/validators";

const props = defineProps<{
  country: string;
  countryOptions: Country[];
}>();

const emit = defineEmits<{
  (e: "update:country", value: string | null): void;
}>();

const { t } = useI18n();

const isActive = ref(false);

const countryOptionsComputed = computed<Country[]>(() => {
  return props.countryOptions ?? [];
});

const countryComputed = computed<string>({
  get: () => {
    return props.country;
  },
  set: (value) => {
    emit("update:country", value);
  },
});

const rules = {
  country: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
