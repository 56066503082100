export class AccountSettingFreightMethod {
  id = "";
  freightAccountPlanId = "";
  freightTaxFreeAccountPlanId = "";
  freightIncomingAccountPlanId = "";
  isDefault = false;

  constructor(freightMethodSetting?: AccountSettingFreightMethod) {
    if (!freightMethodSetting) return;
    this.freightAccountPlanId = freightMethodSetting.freightAccountPlanId;
    this.freightTaxFreeAccountPlanId = freightMethodSetting.freightTaxFreeAccountPlanId;
    this.freightIncomingAccountPlanId = freightMethodSetting.freightIncomingAccountPlanId;
  }
}
