<template>
  <Select
    v-model="freightMethodId"
    :options="filteredFreightMethods"
    optionLabel="name"
    optionValue="id"
    :placeholder="
      t('placeholder.select', { property: t('account-settings.freight-method.freight-method').toLowerCase() })
    "
    class="w-full"
    :data-testid="dataTestId"
    :class="{ 'p-invalid': val && val.$error }"
    :pt:list:data-testid="`${dataTestId}-list`"
  />
  <small class="p-error" v-if="val && val.$error" data-testid="c-account-settings-freight-method-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script lang="ts" setup>
import { required } from "@/locales/i18n-validators";
import { AccountSettingFreightMethod } from "@/models/account-settings/AccountSettingFreightMethod";
import { FreightMethod } from "@/models/freight-method/FreightMethod";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  freightMethods: FreightMethod[];
  freightMethodSettings: AccountSettingFreightMethod[];
  isRequired?: boolean;
  dataTestId?: string;
}>();

const freightMethodId = defineModel<string>("freightMethodId", {
  required: true,
});

const { t } = useI18n();

const filteredFreightMethods = computed(() => {
  return props.freightMethods.filter((freightMethod) => {
    return !props.freightMethodSettings.some((freightMethodSetting) => {
      return freightMethodSetting.id === freightMethod.id && freightMethodId.value !== freightMethod.id;
    });
  });
});

const rules = {
  freightMethodId: {
    required,
  },
};

const val = props.isRequired ? useVuelidate(rules, { freightMethodId }) : null;
</script>
