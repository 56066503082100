<template>
  <DataTable
    :value="attachmentTypes"
    class="c-datatable"
    responsiveLayout="scroll"
    stripedRows
    data-testid="c-account-settings-attachment-types-table"
  >
    <Column field="name" :header="t('account-settings.attachment-types.attachment-type')">
      <template #body="{ data, index }">
        <span :data-testid="`c-account-settings-attachment-type-name-${index}`">{{ data.name }}</span>
      </template>
    </Column>
    <Column :header="t('account-settings.attachment-types.code')" class="max-w-24">
      <template #body="{ data, index }">
        <AttachmentTypeCode v-model:attachmentTypeCode="data.code" :index="index" />
      </template>
    </Column>

    <template #empty>
      <span>{{ t("account-settings.no-attachment-types") }}</span>
    </template>
  </DataTable>
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { AttachmentType } from "@/models/account-settings/AttachmentType";
import AttachmentTypeCode from "./AttachmentTypeCode.vue";

const attachmentTypes = defineModel<AttachmentType[]>("attachmentTypes", {
  required: true,
});

const { t } = useI18n();
</script>
