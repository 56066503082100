import { Client } from "@/models/client/Client";
import { defineStore } from "pinia";
import { ref } from "vue";
import { clientApi } from "../api/client/ClientApi";
import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/api/ErrorHandler";

export const useClientStore = defineStore("client-store", () => {
  const { getAuthHeaders } = useAuth();
  const { handleError } = useErrorHandler();

  const clients = ref<Client[]>([]);

  const getClients = async () => {
    try {
      const authHeaders = await getAuthHeaders();
      clients.value = await clientApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getById = async (id: string): Promise<Client> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await clientApi.getById(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    clients,
    getClients,
    getById,
  };
});
