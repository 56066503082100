<template>
  <div v-if="loadFailed" class="c-creditnotes">
    <Card>
      <template #content>{{ t("creditnotes.load-failed") }} </template>
    </Card>
  </div>
  <div class="c-creditnotes">
    <Card class="m-4">
      <template #content>
        <div>
          <CreditNoteList />
        </div>
      </template>
    </Card>
  </div>
</template>

<script setup lang="ts">
import CreditNoteList from "./CreditNoteList.vue";
import { ref } from "vue";
import { useI18n } from "vue-i18n";

const loadFailed = ref(false);
const { t } = useI18n();
</script>
