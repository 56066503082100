import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "../ErrorHandler";
import { ProductSearchRequest } from "@/models/search/product/ProductSearchRequest";
import { ProductSearchResponse } from "@/models/search/product/ProductSearchResponse";
import { searchApi } from "./SearchApi";

export const useProductSearchService = () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const productSearch = async (request: ProductSearchRequest): Promise<ProductSearchResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await searchApi.productSearch(authHeaders, request);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { productSearch };
};
