<template>
  <span v-if="isOutgoing25Percent" :data-testid="dataTestId">{{ getTaxName }}</span>
  <Select
    v-else
    v-model="taxId"
    :options="filteredTaxes"
    optionLabel="taxName"
    optionValue="id"
    :placeholder="t('placeholder.select', { property: t('account-settings.vat-codes.tax').toLowerCase() })"
    class="w-full"
    :data-testid="dataTestId"
    :class="{ 'p-invalid': val && val.$error }"
    :pt:list:data-testid="`${dataTestId}-list`"
  />
  <small v-if="val && val.$error" class="p-error" data-testid="c-account-settings-vat-codes-error">
    {{ val.$errors[0].$message }}
  </small>
</template>

<script lang="ts" setup>
import { required } from "@/locales/i18n-validators";
import { VatCode } from "@/models/account-settings/VatCode";
import { Tax } from "@/models/tax/Tax";
import useVuelidate from "@vuelidate/core";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  taxes: Tax[];
  vatCodes: VatCode[];
  dataTestId?: string;
  isRequired?: boolean;
  isOutgoing25Percent?: boolean;
}>();

const taxId = defineModel<string>("taxId", {
  required: true,
});

const { t } = useI18n();

const filteredTaxes = computed(() => {
  return props.taxes.filter((tax) => {
    return !props.vatCodes.some((vatCode) => {
      return vatCode.taxId === tax.id && taxId.value !== tax.id;
    });
  });
});

const getTaxName = computed(() => {
  return props.taxes.find((tax) => tax.id === taxId.value)?.taxName;
});

const rules = {
  taxId: {
    required,
  },
};

const val = props.isRequired ? useVuelidate(rules, { taxId }) : null;
</script>
