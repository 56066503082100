import { defineStore } from "pinia";
import { ref } from "vue";
import { webPubSubApi } from "@/api/web-pub-sub/WebPubSubApi";
import { useAuth } from "@cumulus/event-bus";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import { useI18n } from "vue-i18n";

export const useAccountSettingsWebSocketStore = defineStore("account-settings-web-socket-store", () => {
  const { getAuthHeaders } = useAuth();
  const { t } = useI18n();
  const toast = useCumulusToast(useToast());

  const webSocket = ref<WebSocket | null>();

  const setupWebSocket = async () => {
    try {
      const authHeaders = await getAuthHeaders();
      const connectionString = await webPubSubApi.getConnStringForHub(authHeaders, "accountsettings");
      webSocket.value = new WebSocket(connectionString);

      webSocket.value.onerror = () => {
        addErrorToast();
      };
    } catch (error) {
      addErrorToast();
      throw error;
    }
  };

  const addErrorToast = (): void => {
    toast.add({
      severity: "warn",
      summary: t("account-settings.websocket-error"),
      detail: t("account-settings.websocket-error-details"),
      life: 5000,
    });
  };

  return {
    setupWebSocket,
    webSocket,
  };
});
