<template>
  <label for="account-plan-name">
    {{ t(`account-plan.dialog.name.label`) }}
  </label>
  <InputText
    id="account-plan-name"
    v-model="nameComputed"
    data-testid="account-plan-name"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.name.$error }"
    :placeholder="t(`account-plan.dialog.name.placeholder`)"
  />
  <small id="account-plan-name-help" data-testid="account-plan-name-error" class="p-error" v-if="val.name.$error">{{
    val.name.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  name: string;
}>();

const emit = defineEmits<{
  (e: "update:name", value: string): void;
}>();

const nameComputed = computed<string>({
  get: () => {
    return props.name ?? "";
  },
  set: (value) => {
    emit("update:name", value);
  },
});

const rules = {
  name: {
    required: helpers.withMessage(
      t("common.field-required", { fieldName: t("account-plan.dialog.name.label") }),
      required
    ),
  },
};

const val = useVuelidate(rules, props);
</script>
