import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "../ErrorHandler";
import { economyTransferApi } from "./EconomyTransferApi";

export function useEconomyTransferService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getAllEconomyTransfers = async () => {
    try {
      const authHeaders = await getAuthHeaders();
      return await economyTransferApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const getEconomyTransferById = async (id: string) => {
    try {
      const authHeaders = await getAuthHeaders();
      return await economyTransferApi.get(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getAllEconomyTransfers,
    getEconomyTransferById,
  };
}
