import { useAuth } from "@cumulus/event-bus";
import { searchApi } from "./SearchApi";
import { CustomerSearchRequest } from "@/models/search/customer/CustomerSearchRequest";
import { CustomerSearchResponse } from "@/models/search/customer/CustomerSearchResponse";
import { useErrorHandler } from "@/api/ErrorHandler";

export const useCustomerSearchService = () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const customerSearch = async (request: CustomerSearchRequest): Promise<CustomerSearchResponse> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await searchApi.customerSearch(authHeaders, request);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return { customerSearch };
};
