import { type CreditTotal } from "@/models/credit-note/CreditTotals";
import { creditNoteApi } from "./CreditNoteApi";
import { useErrorHandler } from "../ErrorHandler";
import { useAuth } from "@cumulus/event-bus";

export function useCreditNoteService() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getCreditTotals = async (invoiceIds: string[]): Promise<CreditTotal[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      return await creditNoteApi.getCreditTotalsForInvoices(authHeaders, invoiceIds);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getCreditTotals,
  };
}
