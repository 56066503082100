import { Invoice } from "@/models/invoice/Invoice";
import { InvoiceLine } from "@/models/invoice/InvoiceLine";
import { SearchInvoice } from "../search/invoice/SearchInvoice";
import { SearchInvoiceLine } from "../search/invoice/SearchInvoiceLine";
import { SearchProduct } from "../search/product/SearchProduct";

export class CreditableRow {
  invoiceId = "";
  orderId = "";
  customerId = "";
  included = false;
  returnToStock = false;
  positionNumber = 0;

  freightMethodId = "";
  productId = "";
  productNumber = "";
  gtin = "";
  name = "";
  invoiceNumber: number | null = null;

  quantity: number | null = null;
  listPrice: number | null = null;
  invoicedSum = 0;
  invoicedCost = 0;
  vatPercentage = 0;

  quantityToCredit: number | null = null;
  listPriceToCredit: number | null = null;
  sumToCredit = 0;

  previouslyCreditedQuantity = 0;
  previouslyCreditedSum = 0;
  isNoReferenceLine = false;

  static createFromProduct(product: SearchProduct) {
    const creditRow = new CreditableRow();
    creditRow.returnToStock = true;
    creditRow.included = true;
    creditRow.productId = product.id;
    creditRow.productNumber = product.productNumber;
    creditRow.gtin = product.gtin;
    creditRow.name = product.name;
    creditRow.quantity = null;
    creditRow.listPrice = null;
    creditRow.invoicedSum = 0;
    creditRow.vatPercentage = 0;
    creditRow.quantityToCredit = 1;
    creditRow.listPriceToCredit = 0;
    creditRow.sumToCredit = 0;
    return creditRow;
  }

  static createNoReferenceRow(): CreditableRow {
    const creditRow = new CreditableRow();
    creditRow.included = true;
    creditRow.isNoReferenceLine = true;

    creditRow.quantity = null;
    creditRow.listPrice = null;
    creditRow.invoicedSum = 0;
    creditRow.invoicedCost = 0;
    creditRow.vatPercentage = 0;

    creditRow.quantityToCredit = 1;
    creditRow.listPriceToCredit = 0;
    creditRow.sumToCredit = 0;
    return creditRow;
  }

  static createFromInvoiceAndLine(i: Invoice | SearchInvoice, row: InvoiceLine | SearchInvoiceLine): CreditableRow {
    const creditRow = new CreditableRow();

    creditRow.invoiceId = i.id;
    creditRow.orderId = i.orderId;
    creditRow.customerId = i.orderedBy.id;
    creditRow.included = true;
    creditRow.returnToStock = true;
    creditRow.positionNumber = row.positionNumber;

    creditRow.productId = row.productId;
    creditRow.productNumber = row.productNumber;
    creditRow.gtin = row.gtin;
    creditRow.name = row.productName;
    creditRow.invoiceNumber = i.invoiceNumber;

    creditRow.quantity = row.quantity;
    creditRow.listPrice = row.price;
    creditRow.invoicedSum = row.sumLine;
    creditRow.invoicedCost = row.invoicedCost;
    creditRow.vatPercentage = row.vatPercentage;

    creditRow.quantityToCredit = -1;
    creditRow.listPriceToCredit = -1;
    creditRow.sumToCredit = -1;

    creditRow.previouslyCreditedQuantity = 0;
    creditRow.previouslyCreditedSum = 0;
    return creditRow;
  }

  static createFreightRow(i: Invoice | SearchInvoice): CreditableRow {
    const creditRow = new CreditableRow();

    creditRow.invoiceId = i.id;
    creditRow.orderId = i.orderId;
    creditRow.customerId = i.orderedBy.id;
    creditRow.included = true;
    creditRow.returnToStock = false;
    creditRow.positionNumber = -1;

    creditRow.freightMethodId = i.freightMethodId;
    creditRow.productId = "";
    creditRow.productNumber = "";
    creditRow.name = i.freightMethodName;
    creditRow.invoiceNumber = i.invoiceNumber;

    creditRow.quantity = null;
    creditRow.listPrice = i.shippingPrice; // listprice is used to enable editing of this value in the ui
    creditRow.invoicedSum = i.shippingPrice;
    creditRow.invoicedCost = 0;
    creditRow.vatPercentage = i.shippingPriceVatPercentage;

    creditRow.quantityToCredit = -1;
    creditRow.listPriceToCredit = -1;
    creditRow.sumToCredit = -1;

    creditRow.previouslyCreditedQuantity = 0;
    creditRow.previouslyCreditedSum = 0;
    return creditRow;
  }
}
