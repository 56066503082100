import { useAuth } from "@cumulus/event-bus";
import { countryApi } from "./CountryApi";
import { type Country } from "@/models/country/Country";
import { useErrorHandler } from "../ErrorHandler";

export function useCountry() {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const getCountries = async (): Promise<Country[]> => {
    try {
      const authHeaders = await getAuthHeaders();
      const countries = await countryApi.getAll(authHeaders);
      return countries;
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    getCountries,
  };
}
