<template>
  <div v-if="loadFailed" class="c-taxes">
    <Card>
      <template #content>{{ t("tax.load-failed", { errorReason: errorReason }) }} </template>
    </Card>
  </div>
  <DataTable
    v-model:filters="filter"
    :value="taxes"
    dataKey="id"
    :autoLayout="true"
    responsiveLayout="scroll"
    selectionMode="single"
    :loading="loading"
    stripedRows
    sortField="name"
    :sortOrder="1"
    :paginator="false"
    :rows="100"
    class="c-datatable"
    data-testid="c-tax-data-table"
    @row-dblclick="onRowDblClick"
  >
    <Column
      field="taxName"
      :header="t(`tax.column.name`)"
      :sortable="true"
      filterField="taxName"
      filterMatchMode="contains"
      headerClass="w-1/12"
    />
    <Column field="description" :header="t(`tax.column.description`)" :sortable="true" />
    <Column field="rate" :header="t(`tax.column.rate`)" :sortable="true">
      <template #body="{ data }">
        <span>{{ data.rate }}%</span>
      </template>
    </Column>
    <Column field="taxType" :header="t(`tax.column.taxType`)" :sortable="true" />
    <Column field="countryIso" :header="t(`tax.column.countryIso`)" :sortable="true" />
    <Column field="tax.isDefaultTaxForProduct" :header="t(`tax.column.forProduct`)" :sortable="true">
      <template #body="{ data }">
        <Checkbox v-model="data.isDefaultTaxForProduct" class="p-disabled" :binary="true" />
      </template>
    </Column>
    <Column field="tax.isDefaultTaxForFreight" :header="t(`tax.column.forFreight`)" :sortable="true">
      <template #body="{ data }">
        <Checkbox v-model="data.isDefaultTaxForFreight" class="p-disabled" :binary="true" />
      </template>
    </Column>
    <Column field="tax.isActive" :header="t(`tax.column.isActive`)" :sortable="true">
      <template #body="{ data }">
        <Checkbox v-model="data.isActive" class="p-disabled" :binary="true" />
      </template>
    </Column>
    <Column headerClass="w-12">
      <template #body="{ data }">
        <Button type="button" :data-testid="`edit-${data.id}`" text @click="editTax(data)">
          <i class="pi pi-pencil"></i>
        </Button>
      </template>
    </Column>

    <template #empty>{{ t("tax.empty-list") }} </template>
    <template #loading>{{ t("tax.loading-list") }}</template>
  </DataTable>
  <Button type="button" class="p-button-text w-full" data-testid="add-tax-term-btn" @click="showAddDialog = true">
    <i class="c-plus"></i>
    <span class="ml-2 c-default-text">{{ t("common.add") }}</span>
  </Button>

  <TaxAddDialog v-model:showDialog="showAddDialog" />
  <TaxEditDialog v-model:showDialog="showEditDialog" :tax="tax" />
</template>

<script setup lang="ts">
import { useI18n } from "vue-i18n";
import { ref } from "vue";
import TaxAddDialog from "./TaxAddDialog.vue";
import TaxEditDialog from "./TaxEditDialog.vue";
import { FilterMatchMode } from "@primevue/core/api";
import { type DataTableRowDoubleClickEvent } from "primevue/datatable";
import { storeToRefs } from "pinia";
import { useTaxStore } from "@/stores/TaxStore";
import { Tax } from "@/models/tax/Tax";

const { t } = useI18n();
const loadFailed = ref(false);
const errorReason = ref("");
const loading = ref(false);
const filter = ref({
  global: { value: "", matchMode: FilterMatchMode.CONTAINS },
});
const showAddDialog = ref(false);
const showEditDialog = ref(false);

const { taxes } = storeToRefs(useTaxStore());

const onRowDblClick = (event: DataTableRowDoubleClickEvent) => {
  editTax(event.data);
};

const tax = ref<Tax>(new Tax());

const editTax = (value: Tax) => {
  tax.value = value;
  showEditDialog.value = true;
};
</script>
