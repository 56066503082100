import { EconomyTransfer } from "@/models/economy-transfer/EconomyTransfer";
import { EconomyTransferInfo } from "@/models/economy-transfer/EconomyTransferInfo";
import { AuthHeaders, httpClient } from "@cumulus/http";

class EconomyTransferApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_FINANCE != "undefined" && import.meta.env.VITE_APP_API_FINANCE
        ? `${import.meta.env.VITE_APP_API_FINANCE}/economy-transfers`
        : `${import.meta.env.VITE_APP_API_URL as string}/economy-transfers`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<EconomyTransferInfo[]> {
    return await httpClient(this.uri, authHeaders)
      .get("")
      .then(({ data }) => data);
  }

  public async get(authHeaders: AuthHeaders, id: string): Promise<EconomyTransfer> {
    return await httpClient(this.uri, authHeaders)
      .get(`/${id}`)
      .then(({ data }) => data);
  }
}

export const economyTransferApi = new EconomyTransferApi();
