import { httpClient, AuthHeaders } from "@cumulus/http";
import { NumberSeries } from "../../model/NumberSeries";
import { FinanceNumberSeries } from "../../model/finance/FinanceNumberSeries";

class FinanceApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_FINANCE != undefined && import.meta.env.VITE_APP_API_FINANCE
        ? `${import.meta.env.VITE_APP_API_FINANCE as string}`
        : `${import.meta.env.VITE_APP_API_URL as string}`;
  }
  public async getAll(authHeaders: AuthHeaders): Promise<FinanceNumberSeries> {
    return await httpClient(this.uri, authHeaders)
      .get("/finance-number-series")
      .then(({ data }) => data);
  }

  public async updateInvoiceNumberSeries(authHeaders: AuthHeaders, invoiceNumberSeries: NumberSeries): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/invoices/number-series", invoiceNumberSeries);
  }

  public async updateZeroInvoiceNumberSeries(
    authHeaders: AuthHeaders,
    zeroInvoiceNumberSeries: NumberSeries
  ): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/zero-invoices/number-series", zeroInvoiceNumberSeries);
  }

  public async updateCreditNoteNumberSeries(
    authHeaders: AuthHeaders,
    creditNoteNumberSeries: NumberSeries
  ): Promise<void> {
    return await httpClient(this.uri, authHeaders).put("/credit-notes/number-series", creditNoteNumberSeries);
  }
}
const financeApi = new FinanceApi();
export { financeApi };
