<template>
  <Dialog
    v-model:visible="visibleDialog"
    :header="t('account-settings.header-title')"
    :modal="true"
    :breakpoints="{ '2000px': '90vw', '999px': '90vw', '640px': '95vw' }"
  >
    <div v-if="loading" class="c-overlay-container">
      <ProgressSpinner />
    </div>

    <div class="grid grid-cols-12 gap-4">
      <div class="col-span-12">
        <Card>
          <template #content>
            <div class="grid grid-cols-12 gap-4">
              <div class="field col-span-12 md:col-span-4">
                <SelectClients v-model:selectedClientIds="accountSettings.clientIds" :clients="clients" />
              </div>
              <div class="field col-span-12 md:col-span-4">
                <Description v-model:description="accountSettings.description" />
              </div>
              <div class="field col-span-12 md:col-span-4">
                <EconomySystem v-model:economySystem="accountSettings.economySystem" />
              </div>
            </div>
          </template>
        </Card>
      </div>

      <div class="col-span-12 lg:col-span-8">
        <div class="grid grid-cols-12 gap-4">
          <div class="col-span-12">
            <Card>
              <template #content>
                <div class="h-full">
                  <h1 class="flex justify-center">{{ t("account-settings.products") }}</h1>
                  <ProductSettings v-model:productSettings="accountSettings.products" :accountGroups="accountGroups" />
                </div>
              </template>
            </Card>
          </div>

          <div class="col-span-12">
            <Card>
              <template #content>
                <div class="h-full">
                  <h1 class="flex justify-center">{{ t("account-settings.freight-method.label") }}</h1>
                  <FreightMethodSettings :freightMethodSettings="accountSettings.freightMethods" />
                </div>
              </template>
            </Card>
          </div>
        </div>
      </div>

      <div class="col-span-12 lg:col-span-4">
        <Card>
          <template #content>
            <div class="h-full">
              <h1 class="flex justify-center">{{ t("account-settings.attachment-types.label") }}</h1>
              <AttachmentTypeSettings v-model:attachmentTypes="accountSettings.attachmentTypes" />
            </div>
          </template>
        </Card>
      </div>

      <div class="col-span-12 lg:col-span-4">
        <Card>
          <template #content>
            <div class="h-full">
              <h1 class="flex justify-center">{{ t("account-settings.vat-codes.label") }}</h1>
              <VatCodesSettings v-model:vatCodes="accountSettings.vatCodes" />
            </div>
          </template>
        </Card>
      </div>

      <div class="col-span-12 lg:col-span-4">
        <Card>
          <template #content>
            <div class="h-full">
              <h1 class="flex justify-center">{{ t("account-settings.payment-terms.label") }}</h1>
              <PaymentTermsSettings v-model:paymentTermsSettings="accountSettings.paymentTerms" />
            </div>
          </template>
        </Card>
      </div>

      <div class="col-span-12 lg:col-span-4">
        <Card>
          <template #content>
            <div class="h-full">
              <h1 class="flex justify-center">{{ t("account-settings.account-types.label") }}</h1>
              <AccountTypesSettings v-model:accountTypes="accountSettings.accountTypes" />
            </div>
          </template>
        </Card>
      </div>
    </div>

    <template #footer>
      <Button class="c-circular-button mr-4" data-testid="c-account-settings-delete-btn" @click="onConfirmDelete">
        <ProgressSpinner v-if="deleting" class="c-spinner" />
        <i v-else class="pi pi-trash c-delete-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.delete") }}</span>
      </Button>
      <ConfirmPopup data-testid="c-account-settings-confirm-delete"></ConfirmPopup>
      <Button
        class="c-circular-button mr-4"
        data-testid="c-account-settings-cancel-btn"
        @click="visibleDialog = false"
      >
        <i class="pi pi-times c-warning-button c-circular-icon"></i>
        <span class="px-4">{{ t("common.cancel") }}</span>
      </Button>
      <div v-tooltip.bottom="saveButtonTooltip">
        <Button
          class="c-circular-button mr-4"
          data-testid="c-account-settings-save-btn"
          :disabled="!hasUpdates"
          @click="onSave"
        >
          <ProgressSpinner v-if="saving" class="c-spinner" />
          <i v-else class="pi pi-check c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.save") }}</span>
        </Button>
      </div>
    </template>
  </Dialog>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import SelectClients from "./SelectClients.vue";
import Description from "./Description.vue";
import EconomySystem from "./EconomySystem.vue";
import ProductSettings from "./ProductSettings.vue";
import { useI18n } from "vue-i18n";
import { useAccountSettingsService } from "@/api/account-settings/AccountSettingsService";
import { useAccountGroupService } from "@/api/account-group/AccountGroupService";
import { Client } from "@/models/client/Client";
import { AccountSettings } from "@/models/account-settings/AccountSettings";
import { AccountGroup } from "@/models/account-group/AccountGroup";
import FreightMethodSettings from "./FreightMethodSettings.vue";
import AttachmentTypeSettings from "./AttachmentTypeSettings.vue";
import VatCodesSettings from "./VatCodesSettings.vue";
import PaymentTermsSettings from "./PaymentTermsSettings.vue";
import AccountTypesSettings from "./AccountTypesSettings.vue";
import useValidate from "@vuelidate/core";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import { useConfirm } from "primevue/useconfirm";
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";

const props = defineProps<{
  clients: Client[];
  selectedAccountSettingsId: string;
}>();

const visibleDialog = defineModel<boolean>("visibleDialog", {
  required: true,
});

const { getAccountSettings, updateAccountSettings, deleteAccountSettings } = useAccountSettingsService();
const { getAllAccountGroups } = useAccountGroupService();

const loading = ref(false);
const saving = ref(false);
const deleting = ref(false);
const accountSettings = ref<AccountSettings>(new AccountSettings());
const initialAccountSettings = ref<AccountSettings>(new AccountSettings());
const accountGroups = ref<AccountGroup[]>([]);
const { t } = useI18n();
const val = useValidate();
const toast = useCumulusToast(useToast());
const confirm = useConfirm();

const onSave = async () => {
  val.value.$touch();
  await val.value.$validate();
  if (val.value.$error) {
    toast.add({
      severity: "warn",
      summary: t("common.validation-error.summary"),
      detail: t("common.validation-error.detail"),
    });
    return;
  }

  try {
    saving.value = true;

    await updateAccountSettings(accountSettings.value);

    toast.add({
      severity: "success",
      summary: t("account-settings.account-settings-updated"),
    });

    visibleDialog.value = false;
  } finally {
    saving.value = false;
  }
};
const onConfirmDelete = (event: Event) => {
  confirm.require({
    target: event.currentTarget as HTMLElement,
    message: t("account-settings.delete-confirm-message"),
    icon: "pi pi-exclamation-triangle",
    accept: async () => {
      await onDelete();
    },
  });
};

const onDelete = async () => {
  try {
    deleting.value = true;
    await deleteAccountSettings(accountSettings.value.id);

    toast.add({
      severity: "success",
      summary: t("account-settings.account-settings-deleted"),
    });

    visibleDialog.value = false;
  } finally {
    deleting.value = false;
  }
};

const loadData = async () => {
  try {
    loading.value = true;
    accountSettings.value = await getAccountSettings(props.selectedAccountSettingsId);
    accountGroups.value = await getAllAccountGroups();
    initialAccountSettings.value = cloneDeep(accountSettings.value);
  } finally {
    loading.value = false;
  }
};

const hasUpdates = computed(() => {
  return !isEqual(accountSettings.value, initialAccountSettings.value);
});

const saveButtonTooltip = computed(() => {
  return hasUpdates.value ? { value: "" } : { value: t("common.save-tooltip"), showDelay: 100, hideDelay: 300 };
});

onMounted(async () => {
  await loadData();
});
</script>

<style scoped lang="scss">
.c-spinner {
  width: 22.83px;
}

.c-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 3;
}
</style>
