import { httpClient, AuthHeaders } from "@cumulus/http";

export class WebPubSubApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_FINANCE != undefined && import.meta.env.VITE_APP_API_FINANCE
        ? `${import.meta.env.VITE_APP_API_FINANCE as string}/web-pub-sub`
        : `${import.meta.env.VITE_APP_API_URL as string}/web-pub-sub`;
  }

  public async getConnStringForHub(authHeaders: AuthHeaders, hubName: string): Promise<string> {
    return await httpClient(this.uri, authHeaders)
      .get(`/finance/${hubName}/connection-string`)
      .then(({ data }) => data);
  }
}

export const webPubSubApi = new WebPubSubApi();
