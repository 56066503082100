<template>
  <PrimeDialog
    v-model:visible="visibleDialog"
    :header="t('account-settings.add-account-settings')"
    :modal="true"
    :breakpoints="{ '999px': '90vw', '640px': '95vw' }"
    class="w-2/12"
    data-testid="c-account-settings-add-account-settings-dialog"
  >
    <SelectClients v-model:selectedClientIds="selectedClientIds" :clients="clients" />
    <template #footer>
      <div class="flex justify-end">
        <PrimeButton class="c-circular-button mr-4" data-testid="btn-cancel" @click="visibleDialog = false">
          <i class="pi pi-times c-warning-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.cancel") }}</span>
        </PrimeButton>
        <PrimeButton
          class="c-circular-button mr-4"
          data-testid="c-account-settings-create-new-button"
          @click="onAddNewAccountSettings"
          :disabled="selectedClientIds.length === 0"
        >
          <ProgressSpinner class="c-spinner" v-if="saving" />
          <i v-else class="pi pi-check c-success-button c-circular-icon"></i>
          <span class="px-4">{{ t("common.add") }}</span>
        </PrimeButton>
      </div>
    </template>
  </PrimeDialog>
</template>

<script setup lang="ts">
import { Client } from "@/models/client/Client";
import { onMounted, onUnmounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import SelectClients from "./SelectClients.vue";
import { useAccountSettingsService } from "@/api/account-settings/AccountSettingsService";
import { AccountSettingsWebPubSubMessage, EventType } from "@/models/account-settings/AccountSettingsWebPubSubMessage";
import { useCumulusToast } from "@cumulus/toast";
import { useToast } from "primevue/usetoast";
import { storeToRefs } from "pinia";
import { useAccountSettingsWebSocketStore } from "@/stores/AccountSettingsWebSocketStore";

defineProps<{
  clients: Client[];
}>();

const visibleDialog = defineModel<boolean>("visibleDialog", {
  required: true,
});

const emit = defineEmits<{
  (e: "accountSettingsCreated", value: string): void;
}>();

const selectedClientIds = ref<string[]>([]);
const saving = ref(false);
const timeout = ref<NodeJS.Timeout | null>(null);

const { t } = useI18n();
const toast = useCumulusToast(useToast());
const { createDefaultAccountSettings } = useAccountSettingsService();
const accountSettingWebSocketStore = useAccountSettingsWebSocketStore();
const { webSocket } = storeToRefs(accountSettingWebSocketStore);

const onAddNewAccountSettings = async () => {
  saving.value = true;
  try {
    await createDefaultAccountSettings(selectedClientIds.value);
    await scheduleDialogClosing();
  } finally {
    saving.value = false;
  }
};

const scheduleDialogClosing = async (): Promise<void> => {
  await new Promise(() => {
    timeout.value = setTimeout(() => {
      toast.add({
        severity: "success",
        summary: t("account-settings.account-settings-added-summary"),
        detail: t("account-settings.account-settings-soon-added-details"),
        life: 5000,
      });
      saving.value = false;
      visibleDialog.value = false;
    }, 5000);
  });
};

const handleWebSocketMessage = (event: MessageEvent) => {
  const message = JSON.parse(event.data) as AccountSettingsWebPubSubMessage;
  if (message.eventType === EventType.DefaultAccountSettingsCreated && !message.isError) {
    clearTimeout(timeout.value as NodeJS.Timeout);
    emit("accountSettingsCreated", message.id);
    toast.add({
      severity: "success",
      summary: t("account-settings.account-settings-added-summary"),
      detail: t("account-settings.account-settings-added-details"),
      life: 5000,
    });
    visibleDialog.value = false;
    saving.value = false;
  }
  if (message.eventType === EventType.DefaultAccountSettingsCreated && message.isError) {
    clearTimeout(timeout.value as NodeJS.Timeout);
    toast.add({
      severity: "error",
      summary: t("account-settings.account-settings-added-error-summary"),
      detail: message.errorMessage,
      life: 5000,
    });
    visibleDialog.value = false;
    saving.value = false;
  }
};

onMounted(async () => {
  if (webSocket.value) {
    webSocket.value.addEventListener("message", handleWebSocketMessage);
  }
});

onUnmounted(() => {
  if (webSocket.value) {
    webSocket.value.removeEventListener("message", handleWebSocketMessage);
  }
});
</script>

<style scoped lang="scss">
.c-spinner {
  width: 22.83px;
}
</style>
