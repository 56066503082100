<template>
  <label for="currency-select-currency-iso">{{ t("currency.iso") }}</label>
  <Select
    id="currency-select-currency-iso"
    v-model="currencyIsoComputed"
    :options="currencyIsoOptions"
    optionLabel="currencyIso"
    optionValue="currencyIso"
    data-testid="currency-select-currency-iso"
    class="w-full"
    @change="emit('currencyIsoChanged', $event)"
    pt:list:data-testid="currency-select-currency-iso-list"
  />
  <small data-testid="currency-iso-error" class="p-error" v-if="val.currencyIso.$error">{{
    val.currencyIso.$errors[0].$message
  }}</small>
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import useVuelidate from "@vuelidate/core";
import { DropdownChangeEvent } from "primevue/dropdown";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps<{
  currencyIso: string;
  currencyIsoOptions: Record<string, string>[];
}>();

const emit = defineEmits<{
  (e: "currencyIsoChanged", value: DropdownChangeEvent): void;
  (e: "update:currencyIso", value: string): void;
}>();

const currencyIsoComputed = computed<string>({
  get: () => props.currencyIso,
  set: (value: string) => emit("update:currencyIso", value),
});

const { t } = useI18n();

const rules = {
  currencyIso: {
    required,
  },
};

const val = useVuelidate(rules, props);
</script>
