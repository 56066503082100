import { Client } from "@/models/client/Client";
import { AuthHeaders } from "@cumulus/event-bus";
import { httpClient } from "@cumulus/http";

class ClientApi {
  private uri = "";

  constructor() {
    this.uri =
      typeof import.meta.env.VITE_APP_API_COMPANY != undefined && import.meta.env.VITE_APP_API_COMPANY
        ? `${import.meta.env.VITE_APP_API_COMPANY as string}/clients`
        : `${import.meta.env.VITE_APP_API_URL as string}/clients`;
  }

  public async getAll(authHeaders: AuthHeaders): Promise<Client[]> {
    return await httpClient(this.uri, authHeaders)
      .get("/")
      .then(({ data }) => data);
  }

  public async getById(authHeaders: AuthHeaders, id: string): Promise<Client> {
    return await httpClient(this.uri, authHeaders)
      .get("/" + id)
      .then(({ data }) => data);
  }
}

export const clientApi = new ClientApi();
