export class AccountSettingsWebPubSubMessage {
  id = "";
  companyId = "";
  eventType: EventType = EventType.DefaultAccountSettingsCreated;
  isError = false;
  errorMessage = "";
}

export enum EventType {
  DefaultAccountSettingsCreated = "DefaultAccountSettingsCreated",
  AccountSettingsUpdated = "AccountSettingsUpdated",
  AccountSettingsDeleted = "AccountSettingsDeleted",
}
