<template>
  <label for="account-plan-accountNumber">
    {{ t(`account-plan.dialog.accountNumber.label`) }}
  </label>
  <InputText
    id="account-plan-accountNumber"
    v-model="accountNumberComputed"
    data-testid="account-plan-accountNumber"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.accountPlanAccountNumber.$error }"
    :placeholder="t(`account-plan.dialog.accountNumber.placeholder`)"
  />
  <small
    id="account-plan-accountNumber-help"
    data-testid="account-plan-accountNumber-error"
    class="p-error"
    v-if="val.accountPlanAccountNumber.$error"
    >{{ val.accountPlanAccountNumber.$errors[0].$message }}</small
  >
</template>

<script setup lang="ts">
import { useVuelidate } from "@vuelidate/core";
import { helpers, required } from "@vuelidate/validators";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const props = defineProps<{
  accountPlanAccountNumber: string;
}>();

const emit = defineEmits<{
  (e: "update:accountPlanAccountNumber", value: string): void;
}>();

const accountNumberComputed = computed<string>({
  get: () => {
    return props.accountPlanAccountNumber ?? "0";
  },
  set: (value) => {
    emit("update:accountPlanAccountNumber", value);
  },
});

const isANumber = (value: string) => {
  return !isNaN(Number(value));
};

const rules = {
  accountPlanAccountNumber: {
    required: helpers.withMessage(
      t("common.field-required", { fieldName: t("account-plan.dialog.accountNumber.label") }),
      required
    ),
    numericAccountNumber: helpers.withMessage(
      t("account-plan.dialog.accountNumber.error", { fieldName: t("account-plan.dialog.accountNumber.label") }),
      isANumber
    ),
  },
};

const val = useVuelidate(rules, props);
</script>
