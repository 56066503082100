<template>
  <label for="payment-term-description">
    {{ t(`payment-term.dialog.description.label`) }}
  </label>
  <InputText
    id="payment-term-description"
    v-model="description"
    data-testid="payment-term-description"
    class="inputfield w-full"
    :class="{ 'p-invalid': val.description.$error }"
    :placeholder="t(`payment-term.dialog.description.placeholder`)"
  />
  <small
    v-if="val.description.$error"
    id="payment-term-description-help"
    data-testid="payment-term-description-error"
    class="p-error"
    >{{ val.description.$errors[0].$message }}</small
  >
</template>

<script setup lang="ts">
import { required } from "@/locales/i18n-validators";
import { useVuelidate } from "@vuelidate/core";
import { useI18n } from "vue-i18n";

const { t } = useI18n();

const description = defineModel<string>("description", {
  required: true,
});

const rules = {
  description: {
    required: required,
  },
};

const val = useVuelidate(rules, { description: description });
</script>
