import { TaxType } from "./Taxtype";

export class NewTax {
  taxName = "";
  rate = 0;
  taxType = TaxType.Incoming;
  code = "";
  countryIso = "";
  description = "";
  isActive = false;
  isDefaultTaxForProduct = false;
  isDefaultTaxForFreight = false;
}
