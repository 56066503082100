import { Address } from "./Address";

export class CreditNoteCustomer {
  id = "";
  customerNumber = "";
  organizationNumber = "";
  customerName = "";
  phoneNumber = "";
  email = "";
  documentLanguageIso = "";
  address = new Address();
}
