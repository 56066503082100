import { useAuth } from "@cumulus/event-bus";
import { useErrorHandler } from "@/api/ErrorHandler";
import { PaymentTerm } from "@/models/payment-term/PaymentTerm";
import { NewPaymentTerm } from "@/models/payment-term/NewPaymentTerm";
import { defineStore } from "pinia";
import { paymentTermApi } from "@/api/payment-term/PaymentTermApi";
import { ref } from "vue";

export const usePaymentTermStore = defineStore("payment-term-store", () => {
  const { handleError } = useErrorHandler();
  const { getAuthHeaders } = useAuth();

  const paymentTerms = ref<PaymentTerm[]>([]);

  const getPaymentTerms = async () => {
    try {
      const authHeaders = await getAuthHeaders();
      paymentTerms.value = await paymentTermApi.getAll(authHeaders);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const createPaymentTerm = async (paymentTerm: NewPaymentTerm): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await paymentTermApi.create(authHeaders, paymentTerm);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const updatePaymentTerm = async (paymentTerm: PaymentTerm): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await paymentTermApi.update(authHeaders, paymentTerm);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  const deletePaymentTerm = async (id: string): Promise<void> => {
    try {
      const authHeaders = await getAuthHeaders();
      await paymentTermApi.delete(authHeaders, id);
    } catch (error) {
      await handleError(error);
      throw error;
    }
  };

  return {
    paymentTerms,
    getPaymentTerms,
    createPaymentTerm,
    updatePaymentTerm,
    deletePaymentTerm,
  };
});
