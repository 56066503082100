export const CreditNotesLinesColumns = {
  CreditNoteDate: "creditNoteDate",
  CreditNoteNumber: "creditNoteNumber",
  CreditNoteLines: "creditNoteLines",
  OrderedByCustomerNumber: "orderedBy.customerNumber",
  OrderedByCustomerName: "orderedBy.customerName",
  TotalSum: "totalSum",
  CreditComment: "creditComment",
  Pdf: "pdf",
} as const;
